import React from 'react'
import { SvgIcon } from '@mui/material'
import { IconProps } from '../types'

const LineDownarrow:React.FC<IconProps> = ({ color = '#D2D3D4'}) => {
    return (
        <SvgIcon viewBox="0 0 25 24">
            <path fill={color} d="M12.8712 13.1725L17.8738 8.22247L19.3029 9.63647L12.8712 16.0005L6.43945 9.63647L7.8685 8.22247L12.8712 13.1725Z"/>
        </SvgIcon>
    )
}

export default LineDownarrow
