import React from 'react'
import { SvgIcon } from '@mui/material'
import { IconProps } from '../types'

const Game:React.FC<IconProps> = ({ color = '#ffffff'}) => {
    return (
        <SvgIcon viewBox="0 0 16 16">
            <path fill={color} d="M5.33326 4.83333C5.6094 4.83333 5.83326 5.05719 5.83326 5.33333V6.16667H6.6666C6.94274 6.16667 7.1666 6.39052 7.1666 6.66667C7.1666 6.94281 6.94274 7.16667 6.6666 7.16667H5.83326V8C5.83326 8.27614 5.6094 8.5 5.33326 8.5C5.05712 8.5 4.83326 8.27614 4.83326 8V7.16667H3.99993C3.72379 7.16667 3.49993 6.94281 3.49993 6.66667C3.49993 6.39052 3.72379 6.16667 3.99993 6.16667H4.83326V5.33333C4.83326 5.05719 5.05712 4.83333 5.33326 4.83333Z"/>
            <path fill={color} d="M11.3333 7.83333C11.3333 7.46514 11.0348 7.16667 10.6666 7.16667C10.2984 7.16667 9.99993 7.46514 9.99993 7.83333V8C9.99993 8.36819 10.2984 8.66667 10.6666 8.66667C11.0348 8.66667 11.3333 8.36819 11.3333 8V7.83333Z"/>
            <path fill={color} d="M10.6666 4.66667C11.0348 4.66667 11.3333 4.96514 11.3333 5.33333V5.5C11.3333 5.86819 11.0348 6.16667 10.6666 6.16667C10.2984 6.16667 9.99993 5.86819 9.99993 5.5V5.33333C9.99993 4.96514 10.2984 4.66667 10.6666 4.66667Z"/>
            <path fill={color} d="M12.6666 6.66667C12.6666 7.03486 12.3681 7.33333 11.9999 7.33333H11.8333C11.4651 7.33333 11.1666 7.03486 11.1666 6.66667C11.1666 6.29848 11.4651 6 11.8333 6H11.9999C12.3681 6 12.6666 6.29848 12.6666 6.66667Z"/>
            <path fill={color} d="M9.49993 7.33333C9.86812 7.33333 10.1666 7.03486 10.1666 6.66667C10.1666 6.29848 9.86812 6 9.49993 6H9.33326C8.96507 6 8.6666 6.29848 8.6666 6.66667C8.6666 7.03486 8.96507 7.33333 9.33326 7.33333H9.49993Z" />
            <path fill={color} fill-rule="evenodd" clip-rule="evenodd" d="M8.49993 2C8.49993 1.72386 8.27607 1.5 7.99993 1.5C7.72379 1.5 7.49993 1.72386 7.49993 2V2.99125C6.54053 3.00436 5.58151 3.05521 4.62511 3.14381L4.40404 3.16428C2.80958 3.31198 1.58179 4.63604 1.55465 6.23711L1.54659 6.71275C1.51505 8.57347 1.66504 10.4328 1.99442 12.2644C2.15773 13.1725 2.94796 13.8333 3.87063 13.8333H4.01799C4.84156 13.8333 5.58777 13.348 5.92168 12.5951L6.62875 11.0009C7.15615 9.81182 8.84371 9.81182 9.37111 11.0009L10.0782 12.5951C10.4121 13.348 11.1583 13.8333 11.9819 13.8333H12.1292C13.0519 13.8333 13.8421 13.1725 14.0054 12.2644C14.3348 10.4328 14.4848 8.57347 14.4533 6.71275L14.4452 6.23711C14.4181 4.63604 13.1903 3.31198 11.5958 3.16428L11.3747 3.14381C10.4183 3.05521 9.45933 3.00436 8.49993 2.99125V2ZM4.71735 4.13954C6.90106 3.93726 9.0988 3.93726 11.2825 4.13954L11.5036 4.16002C12.5902 4.26067 13.4269 5.16298 13.4453 6.25405L13.4534 6.72969C13.4838 8.52541 13.3391 10.3198 13.0212 12.0874C12.9436 12.5192 12.5679 12.8333 12.1292 12.8333H11.9819C11.5538 12.8333 11.1659 12.581 10.9923 12.1897L10.2852 10.5955C9.40622 8.61364 6.59364 8.61364 5.71462 10.5955L5.00756 12.1897C4.83399 12.581 4.4461 12.8333 4.01799 12.8333H3.87063C3.43197 12.8333 3.05628 12.5192 2.97864 12.0874C2.66076 10.3198 2.51601 8.52541 2.54645 6.72969L2.55451 6.25406C2.573 5.16298 3.4097 4.26067 4.49628 4.16002L4.71735 4.13954Z"/>
        </SvgIcon>
    )
}

export default Game
