// material-ui
import { styled, Theme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import { drawerWidth } from 'config';

// ==============================|| HEADER - APP BAR STYLED ||============================== //

const AppBarStyled = styled(AppBar)(({theme}) => ({
    zIndex: theme.zIndex.drawer + 1,
    // transition: theme.transitions.create(['width', 'margin'], {
    //     easing: theme.transitions.easing.sharp,
    //     duration: theme.transitions.duration.leavingScreen
    // }),
    // marginLeft: drawerWidth,
    width: "100%"
    // width: `calc(100% - ${drawerWidth}px)`
}));

export default AppBarStyled;
