import { Box, Typography } from '@mui/material'
import styled from '@emotion/styled'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { IconProps } from 'components/SvgIcons/types'
import * as IconModule from 'components/SvgIcons' 

const MenuItem = styled.div<{achieved: boolean}>`
    display: flex;
    align-items: center;
    padding: 16px 0 16px 24px;
    margin-bottom: 6px;
    cursor: pointer;
    border-radius: 6px;
    font-size: 20px;
    font-weight: 700;
    border-radius: 12px;
    border: ${({ achieved }) => achieved ? '1px solid #E05364' : 'none' };
    background: ${({ achieved }) => achieved ? 'rgba(224, 83, 100, 0.05)' : 'none' };
    @media screen and (max-width: 900px) {
        padding: 8px 0 8px 12px;
    }
`

const Icons = (IconModule as unknown) as { [key: string]: React.FC<IconProps> };

interface MenuProps {
    label: string
    icon: string
    href: string
}
interface Props {
    onClose?: () => void
}
const DrawerContent:React.FC<Props> = ({ onClose }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const menus: MenuProps [] = [
        {
            label: t('Home'),
            icon: 'HomeIcon',
            href: '/'
        },
        // {
        //     label: t('PoolV2'),
        //     icon: 'HomeIcon',
        //     href: '/poolv2'
        // },
        // {
        //     label: t('Pool'),
        //     icon: 'HomeIcon',
        //     href: '/pool'
        // },
        {
            label: t('Stake'),
            icon: 'StakeIcon',
            href: '/stake'
        },
        {
            label: t('W Stake'),
            icon: 'StakeIcon',
            href: '/stakev2'
        },
        {
            label: t('Trade'),
            icon: 'TradeIcon',
            href: '/swap'
        },
        {
            label: t('Liquidity'),
            icon: 'LiquidityIcon',
            href: '/liquidity'
        },
        {
            label: t('Nodes'),
            icon: 'NodeIcon',
            href: '/nodes'
        },
        {
            label: t('Invite'),
            icon: 'BindIcon',
            href: '/invite'
        },
        {
            label: t('Holders'),
            icon: 'HolderIcon',
            href: '/holder'
        }
    ]
    
    const pushRoute = (route: string | undefined, target?: string | undefined) => {
        if (route && target) {
            window.open(route)
        } else {
            navigate(route ?? '/')
            onClose && onClose()
        }
    }
  
    return (
        <Box>
            {menus.map(menu => {
                const achieved = menu.href === location.pathname
                const Icon = Icons[menu.icon]
                const iconElement = <Icon color={achieved ? '#E05364' : 'rgba(255, 255, 255, 0.4)'} />
                return (
                    // <Link to={menu.href}>
                        
                    // </Link>
                    <MenuItem achieved={achieved} onClick={() => pushRoute(menu.href)}>
                        {iconElement}
                        <Typography color={achieved ? '#E05364' : 'rgba(255, 255, 255, 0.4)'} ml="12px" fontSize="14px">{menu.label}</Typography>
                    </MenuItem>
                )
            })}
        </Box>
    )
}

export default DrawerContent