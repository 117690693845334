import React, { useState } from 'react'
import { Token } from 'Modal'
import { tokens } from 'config/contents/swap'

interface SwapContextProps {
    tokenA: Token
    tokenB: Token
    changeTokenA: (token: Token) => void
    changeTokenB: (token: Token) => void
  }

const SwapContext = React.createContext<SwapContextProps>({ 
    tokenA: tokens[0],
    tokenB: tokens[1],
    changeTokenA: (t: Token) => {},
    changeTokenB: (t: Token) => {},
})

interface Props {
    children: React.ReactNode
}

const SwapContextProvider:React.FC<Props> = ({ children }) => {
  const [tokenA, setTokenA] = useState<Token>(tokens[0])
  const [tokenB, setTokenB] = useState<Token>(tokens[1])

  const changeTokenA = (t: Token) => {
    setTokenA(t)
  }
  const changeTokenB = (t: Token) => {
    setTokenB(t)
  }


  return <SwapContext.Provider value={{
    tokenA,
    tokenB,
    changeTokenA,
    changeTokenB
  }}>{children}</SwapContext.Provider>
}

export { SwapContext, SwapContextProvider }
