import React, { useState, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
// import { useConfigView } from 'hooks/useConfigview'
// import { getMainChain } from 'utils'
import { IConfigView, IConfigABI } from 'Modal'

interface ConfigContextProps {
  slippage: number
  deadline: number
  changeSlippage: (value: number) => void
  changeDeadline: (value: number) => void
}

const ConfigContext = React.createContext<ConfigContextProps>({
  slippage: 0.05,
  deadline: 1200,
  changeSlippage: () => {},
  changeDeadline: () => {}
})

interface Props {
    children: React.ReactNode
}
// This context maintain 2 counters that can be used as a dependencies on other hooks to force a periodic refresh
const ConfigContextProvider:React.FC<Props> = ({ children }) => {
  const [slippage, setSlippage] = useState(Number(window.localStorage.getItem('slippage')) || 0.05)
  const [deadline, setDeadline] = useState(Number(window.localStorage.getItem('deadline')) || 1200)
  const changeSlippage = (value: number) => {
    setSlippage(value)
    window.localStorage.setItem('slippage', value.toString())
  }
  const changeDeadline = (value: number) => {
    setDeadline(value)
    window.localStorage.setItem('deadline', value.toString())
  }
  return (
    <ConfigContext.Provider value={{slippage, deadline, changeSlippage, changeDeadline}}>
      {children}
    </ConfigContext.Provider>
  )
}

export { ConfigContext, ConfigContextProvider }
