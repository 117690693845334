import React from 'react'
import { SvgIcon } from '@mui/material'
import { IconProps } from '../types'

const Node:React.FC<IconProps> = ({ color = '#ffffff'}) => {
    return (
        <SvgIcon viewBox="0 0 24 24">
            <path fill={color} d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM9.5 9L7 11.5L12 16.5L17 11.5L14.5 9H9.5Z" fill-opacity="0.6"/>
        </SvgIcon>
    )
}

export default Node
