import { PoolConfig } from 'Modal'
import { getAlphaPoolBTCAddress, getAlphaPoolETHAddress, getAlphaPoolFILAddress,
    getAlphaPoolDOGEAddress, getAlphaPoolLTCAddress, getAlphaPoolBNBAddress,
    getAlphaPoolSHIBAddress, getAlphaPoolTRXAddress, getAlphaPoolXRPAddress,
    getBakAddress, getBTCAddress, getETHAddress, getFILAddress, getXRPAddress, 
    getLTCAddress, getDOGEAddress, getSHIBAddress, getWBNBAddress, getTRXAddress,
    getBakWAddress,
    getAlphaPoolV2BTCAddress,
    getAlphaPoolV2ETHAddress,
    getAlphaPoolV2FILAddress,
    getAlphaPoolV2XRPAddress,
    getAlphaPoolV2LTCAddress,
    getAlphaPoolV2DOGEAddress,
    getAlphaPoolV2SHIBAddress,
    getAlphaPoolV2BNBAddress,
    getAlphaPoolV2TRXAddress,
 } from 'utils/addressHelpers'
export const AlphaPools: PoolConfig[] = [
    {
        stakeToken: 'bak',
        earnToken: 'btc',
        earnTokenDecimals: 18,
        stakeTokenAddress: getBakAddress(),
        earnTokenAddress: getBTCAddress(),
        poolAddress: getAlphaPoolBTCAddress()
    },
    {
        stakeToken: 'bak',
        earnToken: 'eth',
        earnTokenDecimals: 18,
        stakeTokenAddress: getBakAddress(),
        earnTokenAddress: getETHAddress(),
        poolAddress:  getAlphaPoolETHAddress()
    },
    {
        stakeToken: 'bak',
        earnToken: 'fil',
        earnTokenDecimals: 18,
        stakeTokenAddress: getBakAddress(),
        earnTokenAddress: getFILAddress(),
        poolAddress: getAlphaPoolFILAddress()
    },
    {
        stakeToken: 'bak',
        earnToken: 'xrp',
        earnTokenDecimals: 18,
        stakeTokenAddress: getBakAddress(),
        earnTokenAddress: getXRPAddress(),
        poolAddress: getAlphaPoolXRPAddress()
    },
    {
        stakeToken: 'bak',
        earnToken: 'ltc',
        earnTokenDecimals: 18,
        stakeTokenAddress: getBakAddress(),
        earnTokenAddress: getLTCAddress(),
        poolAddress: getAlphaPoolLTCAddress()
    },
    {
        stakeToken: 'bak',
        earnToken: 'doge',
        earnTokenDecimals: 8,
        stakeTokenAddress: getBakAddress(),
        earnTokenAddress: getDOGEAddress(),
        poolAddress: getAlphaPoolDOGEAddress()
    },
    {
        stakeToken: 'bak',
        earnToken: 'shib',
        earnTokenDecimals: 18,
        stakeTokenAddress: getBakAddress(),
        earnTokenAddress: getSHIBAddress(),
        poolAddress: getAlphaPoolSHIBAddress()
    },
    {
        stakeToken: 'bak',
        earnToken: 'bnb',
        earnTokenDecimals: 18,
        stakeTokenAddress: getBakAddress(),
        earnTokenAddress: getWBNBAddress(),
        poolAddress: getAlphaPoolBNBAddress()
    },
    {
        stakeToken: 'bak',
        earnToken: 'trx',
        earnTokenDecimals: 18,
        stakeTokenAddress: getBakAddress(),
        earnTokenAddress: getTRXAddress(),
        poolAddress: getAlphaPoolTRXAddress()
    }
]

export const AlphaPoolsV2: PoolConfig[] = [
    {
        stakeToken: 'bakw',
        earnToken: 'btc',
        earnTokenDecimals: 18,
        stakeTokenAddress: getBakWAddress(),
        earnTokenAddress: getBTCAddress(),
        poolAddress: getAlphaPoolV2BTCAddress()
    },
    {
        stakeToken: 'bakw',
        earnToken: 'eth',
        earnTokenDecimals: 18,
        stakeTokenAddress: getBakWAddress(),
        earnTokenAddress: getETHAddress(),
        poolAddress:  getAlphaPoolV2ETHAddress()
    },
    {
        stakeToken: 'bakw',
        earnToken: 'fil',
        earnTokenDecimals: 18,
        stakeTokenAddress: getBakWAddress(),
        earnTokenAddress: getFILAddress(),
        poolAddress: getAlphaPoolV2FILAddress()
    },
    {
        stakeToken: 'bakw',
        earnToken: 'xrp',
        earnTokenDecimals: 18,
        stakeTokenAddress: getBakWAddress(),
        earnTokenAddress: getXRPAddress(),
        poolAddress: getAlphaPoolV2XRPAddress()
    },
    {
        stakeToken: 'bakw',
        earnToken: 'ltc',
        earnTokenDecimals: 18,
        stakeTokenAddress: getBakWAddress(),
        earnTokenAddress: getLTCAddress(),
        poolAddress: getAlphaPoolV2LTCAddress()
    },
    {
        stakeToken: 'bakw',
        earnToken: 'doge',
        earnTokenDecimals: 8,
        stakeTokenAddress: getBakWAddress(),
        earnTokenAddress: getDOGEAddress(),
        poolAddress: getAlphaPoolV2DOGEAddress()
    },
    {
        stakeToken: 'bakw',
        earnToken: 'shib',
        earnTokenDecimals: 18,
        stakeTokenAddress: getBakWAddress(),
        earnTokenAddress: getSHIBAddress(),
        poolAddress: getAlphaPoolV2SHIBAddress()
    },
    {
        stakeToken: 'bakw',
        earnToken: 'bnb',
        earnTokenDecimals: 18,
        stakeTokenAddress: getBakWAddress(),
        earnTokenAddress: getWBNBAddress(),
        poolAddress: getAlphaPoolV2BNBAddress()
    },
    {
        stakeToken: 'bakw',
        earnToken: 'trx',
        earnTokenDecimals: 18,
        stakeTokenAddress: getBakWAddress(),
        earnTokenAddress: getTRXAddress(),
        poolAddress: getAlphaPoolV2TRXAddress()
    }
]