import { Box } from '@mui/material'
import styled from '@emotion/styled'
import Logo from 'assets/images/logo.svg'

const Text = styled.div`
    font-weight: 700;
    font-size: 18px;
    line-height: 16px;
    color: #ffffff;
    font-family: 'Prompt';
`

const DrawerHeader = () => {
    return (
        <Box display="flex" alignItems="center" justifyContent="space-between" mb="40px">
            <img width="36px" height="36px" src={Logo} alt="logo" />
            <Text>Bak Swap</Text>
        </Box>
    )
}

export default DrawerHeader