import React from 'react';
import Routes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import useSupportChain from 'hooks/useSupportChain'
import { default_chain } from 'config'

function App() {
  useSupportChain()
  console.log("default_chain", default_chain)
  return (
    <ThemeCustomization>
      <ScrollTop>
        <Routes />
      </ScrollTop>
    </ThemeCustomization>
  )
}

export default App;
