import { useEffect, useState } from 'react';
import { AppBar, Toolbar, useMediaQuery, Box } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import ConnectWallet from 'components/ConnectWallet'
import { MenuIcon } from 'components/SvgIcons'
import LogoImg from 'assets/images/smil_logo.svg'
// import LanguageSelect from 'components/Language';

import AppBarStyled from './AppBarStyled';

interface Props {
    onMenu: (open: boolean) => void
}
const Header:React.FC<Props> = ({onMenu}) => {
    const theme = useTheme();
    const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));
    const [isScroll, setIsScroll] = useState(false);
    const handleMobileClick = () => {
        onMenu(true)
    }
    const eventScroller = () => {
        setIsScroll(document.documentElement.scrollTop >= 3);
    }
    useEffect(() => {
        window.addEventListener('scroll', eventScroller, true);
        return () => {
            window.removeEventListener('scroll', eventScroller, true);
        }
    }, []);

    return (
        <>
            {!matchDownMD ?
                <AppBarStyled
                    sx={(theme) => ({
                        background:  isScroll ? 'rgba(33,39,46, 0.7)' : 'transparent',
                        boxShadow: isScroll ? theme.shadows[10] : 'none',
                        border: 'none'
                    })}
                >
                    <Toolbar sx={{display: 'flex', justifyContent: 'flex-end', padding:"0", height: '80px', boxSizing: 'content-box'}}>
                        {/* <LanguageSelect /> */}
                        <ConnectWallet small />
                    </Toolbar>
                </AppBarStyled> :
                <AppBar
                    position="fixed" elevation={0} color="default"
                    sx={{
                        // borderBottom: `1px solid ${theme.palette.divider}`,
                        background:  isScroll ? 'rgba(33,39,46, 0.7)' : 'transparent',
                        padding: '0  12px'
                    }}
                >
                    <Toolbar
                        sx={{display: 'flex', justifyContent: 'space-between', padding:"0", height: '80px', boxSizing: 'content-box'}}
                    >
                        <Box 
                            onClick={handleMobileClick}
                            sx={{
                            display: 'flex',
                            alignItems: 'center',
                            '> svg': {
                                width: '32px',
                                height: '32px',
                                cursor: 'pointer'
                            }
                        }}>
                            <img style={{marginRight: '10px'}} width="36px" height="36px" src={LogoImg} alt="logo" />
                            <MenuIcon color='#E05364' />
                        </Box>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            {/* <LanguageSelect /> */}
                            <ConnectWallet small />
                        </Box>
                    </Toolbar>
                </AppBar>
            }
        </>
    )
}

export default Header