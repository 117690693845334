import React from 'react'
import { SvgIcon } from '@mui/material'
import { IconProps } from '../types'

const Former:React.FC<IconProps> = ({ color = '#ffffff'}) => {
    return (
        <SvgIcon viewBox="0 0 16 16">
            <path fill={color} fill-rule="evenodd" clip-rule="evenodd" d="M4.35677 2.16675C3.96669 2.16675 3.60242 2.3617 3.38604 2.68626L0.993226 6.27549C0.846738 6.49522 0.82922 6.7766 0.947323 7.01281C2.25673 9.63161 4.07643 11.9622 6.29947 13.8677L7.46661 14.8681C7.77353 15.1312 8.22642 15.1312 8.53334 14.8681L9.70049 13.8677C11.9235 11.9622 13.7432 9.63161 15.0526 7.01281C15.1707 6.7766 15.1532 6.49522 15.0067 6.27549L12.6139 2.68626C12.3975 2.3617 12.0333 2.16675 11.6432 2.16675H4.35677ZM4.21809 3.24096C4.249 3.1946 4.30104 3.16675 4.35677 3.16675H5.91664L4.63989 6.23094C4.61048 6.30152 4.58829 6.37432 4.57333 6.44824C4.10411 6.41332 3.63528 6.37121 3.16703 6.32192L2.22989 6.22327L4.21809 3.24096ZM2.18575 7.22415C3.38135 9.41746 4.96012 11.381 6.84879 13.0209L4.76514 7.46453C4.19692 7.42547 3.62923 7.3761 3.06234 7.31643L2.18575 7.22415ZM5.85652 7.52686L7.99998 13.2428L10.1434 7.52686C8.71521 7.59183 7.28475 7.59183 5.85652 7.52686ZM11.2348 7.46453L9.15117 13.0209C11.0398 11.381 12.6186 9.41746 13.8142 7.22415L12.9376 7.31643C12.3707 7.3761 11.803 7.42546 11.2348 7.46453ZM13.7701 6.22327L12.8329 6.32192C12.3647 6.37121 11.8959 6.41332 11.4266 6.44824C11.4117 6.37431 11.3895 6.30152 11.3601 6.23094L10.0833 3.16675H11.6432C11.6989 3.16675 11.751 3.1946 11.7819 3.24096L13.7701 6.22327ZM10.3944 6.51345C8.79921 6.5963 7.20075 6.5963 5.60551 6.51345L6.99997 3.16675H8.99999L10.3944 6.51345Z"/>
        </SvgIcon>
    )
}

export default Former
