import React from 'react'
import { SvgIcon } from '@mui/material'
import { IconProps } from '../types'

const Home:React.FC<IconProps> = ({ color = '#ffffff'}) => {
    return (
        <SvgIcon viewBox="0 0 24 24">
            <path fill={color} d="M21 19.9998C21 20.265 20.8946 20.5194 20.7071 20.7069C20.5196 20.8944 20.2652 20.9998 20 20.9998H4C3.73478 20.9998 3.48043 20.8944 3.29289 20.7069C3.10536 20.5194 3 20.265 3 19.9998V9.48978C2.99989 9.3374 3.03462 9.187 3.10152 9.05008C3.16841 8.91317 3.26572 8.79335 3.386 8.69978L11.386 2.47778C11.5615 2.34123 11.7776 2.26709 12 2.26709C12.2224 2.26709 12.4385 2.34123 12.614 2.47778L20.614 8.69978C20.7343 8.79335 20.8316 8.91317 20.8985 9.05008C20.9654 9.187 21.0001 9.3374 21 9.48978V19.9998ZM7 14.9998V16.9998H17V14.9998H7Z"/>
        </SvgIcon>
    )
}

export default Home
