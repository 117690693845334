import i18n from 'i18next'
import languageDetector from 'i18next-browser-languagedetector'
import {initReactI18next} from 'react-i18next'

import enUsTrans from 'locales/en-US.json'
// import zhCnTrans from 'locales/zh-CN.json'
import twCnTrans from 'locales/tw-CN.json'

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enUsTrans,
      },
      zh: {
        translation: twCnTrans,
      },
    },
    react: {
      useSuspense: false,
    },
    fallbackLng: 'tw-CN',
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18n
