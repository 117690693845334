import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'Layout';

const Home = Loadable(lazy(() => import('pages/Home')))
const Pool = Loadable(lazy(() => import('pages/Pools')))
const PoolV2 = Loadable(lazy(() => import('pages/BakuPoolsV2')))
const Stake = Loadable(lazy(() => import('pages/Stake')))
const StakeV2 = Loadable(lazy(() => import('pages/StakeV2')))
const Swap = Loadable(lazy(() => import('pages/Swap')))
const Liquidity = Loadable(lazy(() => import('pages/Liquidity')))
const Nodes = Loadable(lazy(() => import('pages/Nodes')))
const Invite = Loadable(lazy(() => import('pages/Invite')))
const Holder = Loadable(lazy(() => import('Layout/Footer')))
const Apprvoe = Loadable(lazy(() => import('pages/Approve')))
const BakuPool = Loadable(lazy(() => import('pages/BakuPools')))

// // render - dashboard
// const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));

// // render - sample page
// const SamplePage = Loadable(lazy(() => import('pages/extra-pages/SamplePage')));

// // render - utilities
// const Typography = Loadable(lazy(() => import('pages/components-overview/Typography')));
// const Color = Loadable(lazy(() => import('pages/components-overview/Color')));
// const Shadow = Loadable(lazy(() => import('pages/components-overview/Shadow')));
// const AntIcons = Loadable(lazy(() => import('pages/components-overview/AntIcons')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <Home />
        },
        {
            path: '/',
            element: <Pool />
        },
        {
            path: '/poolv2',
            element: <PoolV2 />
        },
        {
            path: '/pool',
            element: <BakuPool />
        },
        {
            path: '/stake',
            element: <Stake />
        },
        {
            path: '/stakev2',
            element: <StakeV2 />
        },
        {
            path: '/swap',
            element: <Swap />
        },
        {
            path: '/liquidity',
            element: <Liquidity />
        },
        {
            path: '/nodes',
            element: <Nodes />
        },
        {
            path: '/invite',
            element: <Invite />
        },
        {
            path: '/holder',
            element: <Holder />
        },
        {
            path: '/approve',
            element: <Apprvoe />
        }
    ]
};

export default MainRoutes;
