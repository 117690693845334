import { Token } from 'Modal'
import {
    getUSDTAddress,
    getBakAddress,
    getBakWAddress,
    getRSVAddress,
    getBTCAddress,  
    getETHAddress,
    getFILAddress,
    getXRPAddress,
    getDOGEAddress, 
    getTRXAddress,
    getSHIBAddress,
    getLTCAddress,
    getWBNBAddress,
    getBAKUAddress,
} from 'utils/addressHelpers'
// import { isProduction } from 'utils'

export const tokens: Token[] = [
    {
        symbol: 'USDT',
        name: 'Binance-Peg BSC-USD',
        decimals: 18,
        address: getUSDTAddress(),
        pair: '0x0000000000000000000000000000000000000000'
    },
    {
        symbol: 'BAK',
        name: 'BAK',
        decimals: 18,
        address: getBakAddress(),
        pair: '0xd3CB2c3dDC671fc975198039e7EC53e88E76c2a8'
    },
    {
        symbol: 'KSDT',
        name: 'KSDT',
        decimals: 18,
        address: getBAKUAddress(),
        pair: '0xd3CB2c3dDC671fc975198039e7EC53e88E76c2a8'
    },
    {
        symbol: 'BAKW',
        name: 'BAKW',
        decimals: 18,
        address: getBakWAddress(),
        pair: '0xd3CB2c3dDC671fc975198039e7EC53e88E76c2a8'
    },
    {
        symbol: 'RSV',
        name: 'RSV',
        decimals: 18,
        address: getRSVAddress(),
        pair: '0xd3CB2c3dDC671fc975198039e7EC53e88E76c2a8'
    },
    {
        symbol: 'BNB',
        name: 'BNB',
        decimals: 18,
        address: getWBNBAddress(),
        pair: '0x16b9a82891338f9bA80E2D6970FddA79D1eb0daE'
    },
    {
        symbol: 'BTC',
        name: 'BTC',
        decimals: 18,
        address: getBTCAddress(),
        pair: '0x3F803EC2b816Ea7F06EC76aA2B6f2532F9892d62'
    },
    {
        symbol: 'ETH',
        name: 'Ethereum',
        decimals: 18,
        address: getETHAddress(),
        pair: '0x531FEbfeb9a61D948c384ACFBe6dCc51057AEa7e'
    },
    {
        symbol: 'XRP',
        name: 'XRP',
        decimals: 18,
        address: getXRPAddress(),
        pair: ' 0x3D15D4Fbe8a6ECd3AAdcfb2Db9DD8656c60Fb25c'
    },
    {
        symbol: 'FIL',
        name: 'Filcoin',
        decimals: 18,
        address: getFILAddress(),
        pair: '0x7DcC03aA0129B8b07f2C323391F8aBb0AFb76C52'
    },
    {
        symbol: 'DOGE',
        name: 'doge',
        decimals: 8,
        address: getDOGEAddress(),
        pair: '0x0fA119e6a12e3540c2412f9EdA0221Ffd16a7934'
    },
    {
        symbol: 'TRX',
        name: 'trx',
        decimals: 18,
        address: getTRXAddress(),
        pair: '0x600479D365F1E66a2ac6aa712d9917bB5a85b91f'
    },
    {
        symbol: 'SHIB',
        name: 'shib',
        decimals: 18,
        address: getSHIBAddress(),
        pair: '0x2D515b3A46a6F19957af7b448132f43f95dcad40'
    },
    {
        symbol: 'LTC',
        name: 'ltc',
        decimals: 18,
        address: getLTCAddress(),
        pair: '0x36bb11B22CB3611483214289e19408701744FBf9'
    },
    {
        symbol: 'WBNB',
        name: 'wbnb',
        decimals: 18,
        address: getWBNBAddress(),
        pair: '0x16b9a82891338f9bA80E2D6970FddA79D1eb0daE'
    },

]