import React from 'react'
import { SvgIcon } from '@mui/material'
import { IconProps } from '../types'

const Stake:React.FC<IconProps> = ({ color = '#ffffff'}) => {
    return (
        <SvgIcon viewBox="0 0 24 24">
            <path fill={color} d="M18 20H6V22H4V20H3C2.73478 20 2.48043 19.8946 2.29289 19.7071C2.10536 19.5196 2 19.2652 2 19V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V19C22 19.2652 21.8946 19.5196 21.7071 19.7071C21.5196 19.8946 21.2652 20 21 20H20V22H18V20ZM11 13.874V17H13V13.874C13.9405 13.6283 14.7594 13.0486 15.3038 12.2433C15.8482 11.438 16.0809 10.4621 15.9584 9.49778C15.836 8.53346 15.3667 7.64669 14.6383 7.00303C13.9099 6.35937 12.9721 6.00284 12 6C11.0257 5.99904 10.0846 6.35368 9.35327 6.99737C8.62193 7.64106 8.15067 8.52954 8.02792 9.49605C7.90517 10.4625 8.13939 11.4406 8.68661 12.2467C9.23383 13.0528 10.0564 13.6314 11 13.874ZM12 12C11.4696 12 10.9609 11.7893 10.5858 11.4142C10.2107 11.0391 10 10.5304 10 10C10 9.46957 10.2107 8.96086 10.5858 8.58579C10.9609 8.21071 11.4696 8 12 8C12.5304 8 13.0391 8.21071 13.4142 8.58579C13.7893 8.96086 14 9.46957 14 10C14 10.5304 13.7893 11.0391 13.4142 11.4142C13.0391 11.7893 12.5304 12 12 12Z" fill-opacity="0.6"/>
        </SvgIcon>
    )
}

export default Stake