import { ethers } from 'ethers'
import { getUrlProvider } from 'utils/providers'
import { getMulticallAddress } from 'utils/addressHelpers'
import { default_chain } from 'config'
import Erc20ABI from 'config/abi/ERC20.json'
import MultiCallABI from 'config/abi/Multicall.json'
import ConfigViewABI from 'config/abi/ConfigView.json'

export const getContract = (abi: any, address: string, chainId?: number, signer?: ethers.Signer | ethers.providers.Provider) => {
    const signerOrProvider = signer ?? getUrlProvider(chainId ?? default_chain)
    return new ethers.Contract(address, abi, signerOrProvider)
}

export const getErc20Contract = (address: string, chainId?: number,) => {
    return getContract(Erc20ABI, address, chainId ?? default_chain)
}

export const getConfigViewContract = (chainId?: number) => {
    return getContract(ConfigViewABI, '', chainId)
}

export const getMulticallContract = (chainId?: number) => {
    return getContract(MultiCallABI, getMulticallAddress(chainId), chainId)
}