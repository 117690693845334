import { useState } from 'react'
import { Button } from '@mui/material'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import { useWeb3React } from '@web3-react/core'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import ConnectWalletModal from 'components/ConnectWalletModal'

const ButtonStyle = styled(Button)<{small?: boolean}>`
    padding: ${({ small }) => small ? '8px 14px' : '14px 20px'};
`
interface IButton {
    variant?: string
    small?: boolean
}

const ConnectWallet:React.FC<IButton> = ({ variant, small, ...props }) => {
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const { account } = useWeb3React()

    const handleClickOpen = () => {
        setOpen(true)
    }
    const handleClickClose = () => {
        setOpen(false)
    }

    return (
        <>
            <ButtonStyle small size="large" onClick={handleClickOpen} {...props}>
                {account ? `${account.substring(0, 4)}...${account.substring(account.length - 4)}` :
                    <>{t('Connect Wallet')}</>
                }
            </ButtonStyle>
            <ConnectWalletModal open={open} onClose={handleClickClose}/>
        </>
    )
}

export default ConnectWallet
