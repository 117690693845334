import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Web3Provider } from '@ethersproject/providers'
import { Web3ReactProvider } from '@web3-react/core'
import Web3ReactManager from 'components/Web3ReactManager'
import {
  ExternalProvider,
  JsonRpcFetchFunc,
} from '@ethersproject/providers/src.ts/web3-provider'
import { RefreshContextProvider } from 'contexts/RefreshContext'
import { ToastsProvider } from 'contexts/ToastsProver'
import { PricesProvider } from 'contexts/PricesContext'
import { ConfigContextProvider } from 'contexts/ConfigContext'
import { SwapContextProvider } from 'contexts/SwapContext'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
// import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n'

function getLibrary(
  provider: ExternalProvider | JsonRpcFetchFunc
): Web3Provider {
  const library = new Web3Provider(provider, 'any')
  library.pollingInterval = 15000
  return library
}

if (window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = true
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Web3ReactManager>
          <ConfigContextProvider>
            <RefreshContextProvider>
              <SwapContextProvider>
                <ToastsProvider>
                  <PricesProvider>
                    <ToastContainer
                      position='top-right'
                      hideProgressBar={true}
                      newestOnTop={false}
                      pauseOnFocusLoss
                      pauseOnHover
                      closeOnClick={false}
                    />
                    <App />
                  </PricesProvider>
                </ToastsProvider>
              </SwapContextProvider>
            </RefreshContextProvider>
          </ConfigContextProvider>
        </Web3ReactManager>
      </Web3ReactProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
