import React from 'react'
import { SvgIcon } from '@mui/material'
import { IconProps } from '../types'

const Liquidty:React.FC<IconProps> = ({ color = '#ffffff'}) => {
    return (
        <SvgIcon viewBox="0 0 24 24">
            <path fill={color} d="M3 10L3 3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2L20 2C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3L21 10C20.4696 10 19.9609 10.2107 19.5858 10.5858C19.2107 10.9609 19 11.4696 19 12C19 12.5304 19.2107 13.0391 19.5858 13.4142C19.9609 13.7893 20.4696 14 21 14L21 21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22L4 22C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21L3 14C3.53043 14 4.03914 13.7893 4.41421 13.4142C4.78929 13.0391 5 12.5304 5 12C5 11.4696 4.78929 10.9609 4.41421 10.5858C4.03914 10.2107 3.53043 10 3 10ZM16 6L8 6L8 8L16 8L16 6ZM16 16L8 16L8 18L16 18L16 16Z" fill-opacity="0.6"/>
        </SvgIcon>
    )
}

export default Liquidty
