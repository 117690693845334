import React from 'react'
import { SvgIcon } from '@mui/material'
import { IconProps } from '../types'

const Setting:React.FC<IconProps> = ({ color = '#7C8084'}) => {
    return (
        <SvgIcon viewBox="0 0 24 25">
            <path fill={color} d="M12 1.77905L21.5 7.27905V18.2791L12 23.7791L2.5 18.2791V7.27905L12 1.77905ZM12 4.09005L4.5 8.43205V17.1261L12 21.4681L19.5 17.1261V8.43205L12 4.09005ZM12 16.7791C10.9391 16.7791 9.92172 16.3576 9.17157 15.6075C8.42143 14.8573 8 13.8399 8 12.7791C8 11.7182 8.42143 10.7008 9.17157 9.95063C9.92172 9.20048 10.9391 8.77905 12 8.77905C13.0609 8.77905 14.0783 9.20048 14.8284 9.95063C15.5786 10.7008 16 11.7182 16 12.7791C16 13.8399 15.5786 14.8573 14.8284 15.6075C14.0783 16.3576 13.0609 16.7791 12 16.7791ZM12 14.7791C12.5304 14.7791 13.0391 14.5683 13.4142 14.1933C13.7893 13.8182 14 13.3095 14 12.7791C14 12.2486 13.7893 11.7399 13.4142 11.3648C13.0391 10.9898 12.5304 10.7791 12 10.7791C11.4696 10.7791 10.9609 10.9898 10.5858 11.3648C10.2107 11.7399 10 12.2486 10 12.7791C10 13.3095 10.2107 13.8182 10.5858 14.1933C10.9609 14.5683 11.4696 14.7791 12 14.7791Z" />
        </SvgIcon>
    )
}

export default Setting