import { useEffect, useState } from 'react'
import { getOracleAddress, getBakAddress, getBakWAddress } from 'utils/addressHelpers'
import { useWeb3React } from '@web3-react/core'
import OracleABI from 'config/abi/Oracle.json'
import multicall, { Call } from 'utils/muticall'
import { AlphaPools } from 'config/contents/pools'
import useRefresh from 'hooks/useRefresh'
import { PriceData } from 'Modal'
import BigNumber from 'bignumber.js'

const usePrices = () => {
    const { slowRefresh } = useRefresh()
    const [loading, setLoading] = useState(false)
    const [prices, setPrices] = useState<PriceData []>([])
    const { chainId } = useWeb3React()
    const oracleAddress = getOracleAddress()
    const bakAddress = getBakAddress()
    const bakwAddress = getBakWAddress()
    const fetchPrices = async () => {
        const poolTokens: PriceData [] = AlphaPools.map(d => ({
            symbol: d.earnToken,
            address: d.earnTokenAddress,
            price: ''
        }))
        const bakToken: PriceData = {
            symbol: 'bak',
            address: bakAddress,
            price: ''
        }
        const bakwToken: PriceData = {
            symbol: 'bakw',
            address: bakwAddress,
            price: ''
        }
        const tokens = [bakToken, bakwToken, ...poolTokens]
        const calls: Call [] = tokens.map(d => ({
            address: oracleAddress,
            name: 'currentPrice',
            params: [d.address]
        }))
        const result = await multicall(OracleABI, calls, chainId)
        const tokenPrices = tokens.map((d, i) => ({
            ...d,
            price: new BigNumber(result[i][0].toString()).div(Math.pow(10, 18)).toFixed()
        }))
        setPrices(tokenPrices)
        setLoading(true)
    }
    useEffect(() => {
        if (chainId) {
            fetchPrices()
        }
    }, [chainId, slowRefresh])

    return { prices, loading, fetchPrices }
}

export default usePrices