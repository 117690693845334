import { ethers } from 'ethers'
import { useMemo } from 'react'
import { useWeb3React } from '@web3-react/core'
// import { default_chain, default_chain_TESTNET } from '@w3u/chains'
import { default_chain } from 'config'
import { getUrlProvider } from 'utils/providers'
import { getContract } from 'utils/contractHelpers'
import { getMulticallAddress, getBakSwapAddress, getRouterAddress, getBAKUPoolAddress, getBAKUPoolV2Address } from 'utils/addressHelpers'

import MultiCallABI from 'config/abi/Multicall.json'
import Erc20ABI from 'config/abi/ERC20.json'
import AlphaPoolABI from 'config/abi/AlphaPool.json'
import BatePoolABI from 'config/abi/BatePool.json'
import RsvPoolABI from 'config/abi/RSVPool.json'
import RewardClaimABI from 'config/abi/RewardClaim.json'
import SwapABI from 'config/abi/Swap.json'
import RouterABI from  'config/abi/Router.json'
import BAKUPoolABI from 'config/abi/BAKUPool.json'
import BAKUPoolV2ABI from 'config/abi/BAKUPoolV2.json'

const getSinger = (library: any, chainId?: number) => {
    return library ? library.getSigner() : getUrlProvider(chainId ?? default_chain)
}

export const useErc20Contract = (address: string) => {
    const { chainId, library } = useWeb3React()
    return useMemo(() => getContract(Erc20ABI, address, chainId, getSinger(library, chainId)), [library, chainId, address]) 
}

export const useContract = (abi: any, address: string, chainID?: number) => {
    const { chainId, library } = useWeb3React()
    const _chainId = chainID ?? chainId
    return useMemo(() => getContract(abi, address, _chainId, getSinger(library, _chainId)), [library, _chainId, address])
}
export const getMulticallContract = (chainId?: number) => {
    return getContract(MultiCallABI, getMulticallAddress(chainId), chainId)
}

export const useAlphaPoolContract = (address: string) => {
    const { chainId, library } = useWeb3React()
    const _chainId = chainId ?? default_chain
    return useMemo(() => getContract(AlphaPoolABI, address, _chainId, getSinger(library, _chainId)), [library, _chainId])
}
export const useBatePoolContract = (address: string) => {
    const { chainId, library } = useWeb3React()
    const _chainId = chainId ?? default_chain
    return useMemo(() => getContract(BatePoolABI, address, _chainId, getSinger(library, _chainId)), [library, _chainId])
}

export const useRewardClaimContract = (address: string) => {
    const { chainId, library } = useWeb3React()
    const _chainId = chainId ?? default_chain
    return useMemo(() => getContract(RewardClaimABI, address, _chainId, getSinger(library, _chainId)), [library, _chainId])
}

export const useSwapContract = () => {
    const { chainId, library } = useWeb3React()
    const _chainId = chainId ?? default_chain
    const address = getBakSwapAddress()
    return useMemo(() => getContract(SwapABI, address, _chainId, getSinger(library, _chainId)), [library, _chainId])
}

export const useRouterContract = () => {
    const { chainId, library } = useWeb3React()
    const _chainId = chainId ?? default_chain
    const address = getRouterAddress()
    return useMemo(() => getContract(RouterABI, address, _chainId, getSinger(library, _chainId)), [library, _chainId])
}
export const useBAKUPoolContract = () => {
    const { chainId, library } = useWeb3React()
    const _chainId = chainId ?? default_chain
    const address = getBAKUPoolAddress()
    return useMemo(() => getContract(BAKUPoolABI, address, _chainId, getSinger(library, _chainId)), [library, _chainId])
}
export const useBAKUPoolV2Contract = () => {
    const { chainId, library } = useWeb3React()
    const _chainId = chainId ?? default_chain
    const address = getBAKUPoolV2Address()
    return useMemo(() => getContract(BAKUPoolV2ABI, address, _chainId, getSinger(library, _chainId)), [library, _chainId])
}

export const useRsvPoolContract = (address: string) => {
    const { chainId, library } = useWeb3React()
    const _chainId = chainId ?? default_chain
    return useMemo(() => getContract(RsvPoolABI, address, _chainId, getSinger(library, _chainId)), [library, _chainId])
}

