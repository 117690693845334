import React from 'react'
import { Dialog, Box, Typography } from '@mui/material'
import styled from '@emotion/styled'
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { useWeb3React } from '@web3-react/core'
import { useTranslation } from 'react-i18next'
import { injectedConnector } from 'config/connectors'

import WalletConnectIcon from 'assets/images/wallet/walletconnect.png'
import WalletConnectSolidIcon from 'assets/images/wallet/walletconnect_solid.png'
import MetaMaskIcon from 'assets/images/wallet/metamask.png'
import MetaMaskSolidIcon from 'assets/images/wallet/metamask_solid.png'
import CoinbaseIcon from 'assets/images/wallet/coinbase.png'
import CoinbaseSolidIcon from 'assets/images/wallet/coinbase_solid.png'

const DialogWrapper = styled.div`
    padding: 30px;
    background: #252B32;
    border-radius: 24px;
`
const Flex = styled(Box)`
    display: flex;
`
const Text = styled(Typography)`
    font-size: 12px;
`

const WalletData = [
    {
        name: 'Metamask',
        icon: MetaMaskIcon,
        solidIcon: MetaMaskSolidIcon,
        bgColor: 'linear-gradient(to right, #FF8901, #FF6900)',
    },
    {
        name: 'WalletConnect',
        icon: WalletConnectIcon,
        solidIcon: WalletConnectSolidIcon,
        bgColor: 'linear-gradient(to right, #00A1FF, #0068FF)',
    },
    {
        name: 'Coinbase Wallet',
        icon: CoinbaseIcon,
        solidIcon: CoinbaseSolidIcon,
        bgColor: 'linear-gradient(to right, #315DF5, #002BD3)',
    },

]

const DialogTitle = styled.div`
    display:flex;
    align-items: center;
    justify-content: space-between;
    color: #FFFFFF;
    font-family: 'Barlow';
    font-size: 18px;
`

const WalletList = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 37px;
`

const WalletItem = styled.div<{bgColor: string}>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${({ bgColor }) => bgColor};
    cursor: pointer;
    color: #FFFFFF;
    padding: 0 20px;
    border-radius: 14px;
    width: 512px;
    &:not(:last-child) {
        margin-bottom: 30px;
    }
    >img:first-of-type {
        width: 56px;
        height: 56px;
    }
    >img:last-of-type {
        width: 20px;
        height: 20px;
    }
`
const AccountBox = styled.div`
    padding: 20px;
    border-radius: 12px;
    background-color: #00000099;
    width: 512px;
`
const Account = styled.div`
    padding: 16px;
    color: #ffffff;
    border-radius: 16px;
    margin-top: 13px;
`
interface ModalProps {
    open: boolean
    onClose: () => void
}

const ConnectWalletModal: React.FC<ModalProps> = ({ open, onClose }) => {
    const { t } = useTranslation()
    const { activate, account } = useWeb3React()

    const handleConnectWallet = () => {
        activate(injectedConnector, (e) => console.error(e), true)
        onClose()
    }

    return (
        <Dialog open={open} keepMounted onClose={onClose}>
            <DialogWrapper>
                <DialogTitle>
                    {account ? <Text fontSize="20px">{t('Account')}</Text> : <Text>{t('Connect Wallet')}</Text>}
                    <Text sx={{cursor: 'pointer'}} onClick={onClose}><CloseOutlinedIcon /></Text>
                </DialogTitle>
                {account ? 
                    <AccountBox>
                        <Flex alignItems="center" justifyContent="space-between">
                            <Text color="#ffffff">{t('Connected with MetaMask')}</Text>
                            {/* <Button variant='outlined'>Change</Button> */}
                        </Flex>
                        <Account>{account}</Account>
                        {/* <Flex mt="20px">
                            <Copy name={t('Copy Address')} value={account} />
                            <Link to="/" style={{marginLeft: '16px'}}>
                                <Flex>
                                    <Text mr="10px">{t('View on explorer')}</Text>
                                    <OpenInNewOutlinedIcon />
                                </Flex>
                            </Link>
                        </Flex> */}
                    </AccountBox> :
                    <WalletList>
                        {WalletData.map(d => 
                            <WalletItem
                                key={d.name}
                                bgColor={d.bgColor} 
                                onClick={() => handleConnectWallet()}
                            >
                                <Flex alignItems="center">
                                    <img width={56} height={56} src={d.icon} alt={d.name} />
                                    <Text ml="12px">{d.name}</Text>
                                </Flex>
                                <Flex alignItems="center">
                                    <img width={20} height={20} src={d.solidIcon} alt={d.name} />
                                    <ArrowForwardIosOutlinedIcon sx={{width: '12px', marginLeft: '20px'}} />
                                </Flex>
                            </WalletItem>
                        )}
                    </WalletList>
                }
            </DialogWrapper>
        </Dialog>
    )
}

export default ConnectWalletModal
