import {CHAIN_ETHER, CHAIN_ETHER_KOVAN, CHAIN_OPTIMISM, Chains} from '@w3u/chains'

/**
 * Prompt the user to add a network to the Metamask, and if the wallet is on a different network, switch to the corresponding network
 * @returns {boolean} true if the setup succeeded, false otherwise
 */
export const setupNetwork = async (chainID: number): Promise<boolean> => {
  // const provider = createMetaMaskProvider()
  const provider = (window as Window).ethereum

  if (provider) {
    try {
      if (chainID === CHAIN_ETHER || chainID === CHAIN_ETHER_KOVAN) {
        // @ts-ignore
        const a = await provider.request({method: 'wallet_switchEthereumChain', params: [{chainId: `0x${chainID.toString(16)}`}]})
      } else {
        // @ts-ignore
        await provider.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: `0x${chainID.toString(16)}`,
              chainName: Chains[chainID].name,
              nativeCurrency: {
                name: Chains[chainID].symbol,
                symbol: Chains[chainID].symbol,
                decimals: Chains[chainID].decimals,
              },
              rpcUrls: [Chains[chainID].rpc],
              blockExplorerUrls: [Chains[chainID].explorer],
            },
          ],
        })
      }
      return true
    } catch (error) {
      console.error(error)
      if (chainID === CHAIN_OPTIMISM) {
        // @ts-ignore
        await provider.request({method: 'wallet_switchEthereumChain', params: [{chainId: `0x${chainID.toString(16)}`}]})
      }
      return false
    }
  } else {
    console.error("Can't setup the BSC network on metamask because window.ethereum is undefined")
    return false
  }
}

export const registerToken = async (
  tokenAddress: string,
  tokenSymbol: string,
  tokenDecimals: number,
  tokenImage?: string
) => {
  const provider = (window as Window).ethereum
  if (provider) {
    try {
      // @ts-ignore
      const tokenAdded = await provider.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: tokenDecimals,
            image: tokenImage
          }
        }
      })
      return tokenAdded
    } catch (e) {
      console.error(e)
    }
  }
}
