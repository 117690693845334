import { CHAIN_BSC, CHAIN_BSC_TESTNET } from '@w3u/chains'

export const MULTICALL: {[chainID: number]: string} = {
    [CHAIN_BSC]: '0x1Ee38d535d541c55C9dae27B12edf090C608E6Fb',
    [CHAIN_BSC_TESTNET]: '0x301907b5835a2d723Fe3e9E8C5Bc5375d5c1236A',
}

export const ALPHA_BTC_POOL: {[chainID: number]: string} = {
    [CHAIN_BSC]: '0xeccBF25DdB2E973dc56E0B568c1DD28EB1d6Ea0A',
    [CHAIN_BSC_TESTNET]: '0x0488f14f608b2E9e226B4Aa05afBBeB73EbdE3fe',
}

export const ALPHA_ETH_POOL: {[chainID: number]: string} = {
    [CHAIN_BSC]: '0xF76671D4d8B55ab0F7F7c50D85B99D45526ac884',
    [CHAIN_BSC_TESTNET]: '0x8146e3e0B0A6d8c28935Cc2dE3d276f6b7AF9EcF',
}
export const ALPHA_FIL_POOL: {[chainID: number]: string} = {
    [CHAIN_BSC]: '0xdf92Ba22e5A2129483b33F4D8a7af82c7b6E735c',
    [CHAIN_BSC_TESTNET]: '0x6BC4eAd2b47Cb5220C0C45f15A7A8Fa932274643',
}
export const ALPHA_XRP_POOL: {[chainID: number]: string} = {
    [CHAIN_BSC]: '0x2dCE8712959279F0a9914E077981a782e00cD2B8',
    [CHAIN_BSC_TESTNET]: '',
}
export const ALPHA_LTC_POOL: {[chainID: number]: string} = {
    [CHAIN_BSC]: '0x9F0d65E31c5a77EFd22ea6C132C39a53a82E5edc',
    [CHAIN_BSC_TESTNET]: '',
}
export const ALPHA_DOGE_POOL: {[chainID: number]: string} = {
    [CHAIN_BSC]: '0x6b532FAb7DaDB659066B1782a7CDF95E7D637671',
    [CHAIN_BSC_TESTNET]: '',
}
export const ALPHA_SHIB_POOL: {[chainID: number]: string} = {
    [CHAIN_BSC]: '0xfe22fa9cd789C829212f3d8E6e0860c5d81cCFe9',
    [CHAIN_BSC_TESTNET]: '',
}
export const ALPHA_TRX_POOL: {[chainID: number]: string} = {
    [CHAIN_BSC]: '0x1de2ed4F32F165eB7BAe3DD0e919A0508EECdf8A',
    [CHAIN_BSC_TESTNET]: '',
}
export const ALPHA_BNB_POOL: {[chainID: number]: string} = {
    [CHAIN_BSC]: '0xAd16e96a176277D37c299A087a05e1F5dbA69999',
    [CHAIN_BSC_TESTNET]: '',
}

export const ALPHA_BTC_POOL_V2: {[chainID: number]: string} = {
    [CHAIN_BSC]: '0xf89701923dd5213BeEcD964639d1312B47301f4c',
    [CHAIN_BSC_TESTNET]: '0x0488f14f608b2E9e226B4Aa05afBBeB73EbdE3fe',
}
export const ALPHA_ETH_POOL_V2: {[chainID: number]: string} = {
    [CHAIN_BSC]: '0x19393484215ecC290c714186731F40CD18b0db9c',
    [CHAIN_BSC_TESTNET]: '0x8146e3e0B0A6d8c28935Cc2dE3d276f6b7AF9EcF',
}
export const ALPHA_FIL_POOL_V2: {[chainID: number]: string} = {
    [CHAIN_BSC]: '0x7334ec123D91c14E50b0d824625915594e851096',
    [CHAIN_BSC_TESTNET]: '0x6BC4eAd2b47Cb5220C0C45f15A7A8Fa932274643',
}
export const ALPHA_XRP_POOL_V2: {[chainID: number]: string} = {
    [CHAIN_BSC]: '0xFBf7a6e718f6cF7dD9F97b4Ff0D85743F0450D10',
    [CHAIN_BSC_TESTNET]: '',
}
export const ALPHA_LTC_POOL_V2: {[chainID: number]: string} = {
    [CHAIN_BSC]: '0x46fD8b45EFCD28393d364225f89b59329c253911',
    [CHAIN_BSC_TESTNET]: '',
}
export const ALPHA_DOGE_POOL_V2: {[chainID: number]: string} = {
    [CHAIN_BSC]: '0x5bDDdb67A0388de420065c81Ca59735137cB9f83',
    [CHAIN_BSC_TESTNET]: '',
}
export const ALPHA_SHIB_POOL_V2: {[chainID: number]: string} = {
    [CHAIN_BSC]: '0xFC9836df37554483fbcfb6327f140B00b12Ed2bC',
    [CHAIN_BSC_TESTNET]: '',
}
export const ALPHA_TRX_POOL_V2: {[chainID: number]: string} = {
    [CHAIN_BSC]: '0x02B567D62B8394B859Edb4Db4469BC5EfB418e4D',
    [CHAIN_BSC_TESTNET]: '',
}
export const ALPHA_BNB_POOL_V2: {[chainID: number]: string} = {
    [CHAIN_BSC]: '0x0cBc73440515FA8BbbAEbf5FdfecCF4F74B4a958',
    [CHAIN_BSC_TESTNET]: '',
}

export const BATE_POOL: {[chainID: number]: string} = {
    [CHAIN_BSC]: '0xEa276292E13583fBAB108fC771E51740d6BB7AA5',
    [CHAIN_BSC_TESTNET]: '0x083c9E024e987417F71c4427023e5cF616C7a378',
}

export const INVITE_ROOT : {[chainID: number]: string} = {
    [CHAIN_BSC]: '0xCe141Df60310266396D8Ba062e99dc8808Acf673',
    // [CHAIN_BSC]: '0x8C03B4465d4438723224AfF75f85FA6cbFee2DCc',
    [CHAIN_BSC_TESTNET]: '0x8C03B4465d4438723224AfF75f85FA6cbFee2DCc'
}
export const INVITE_ROOT_BAKU : {[chainID: number]: string} = {
    [CHAIN_BSC]: '',
    [CHAIN_BSC_TESTNET]: '0x8C03B4465d4438723224AfF75f85FA6cbFee2DCc'
}

export const REWARD_CLAIM : {[chainID: number]: string} = {
    [CHAIN_BSC]: '0x5FeF990371E219270F2dC9947dc55C273930327E',
    [CHAIN_BSC_TESTNET]: '0x388180Ef43F188fe1DfDeF8D9f214b49AeB407E0'
}
export const REWARD_CLAIM_2 : {[chainID: number]: string} = {
    [CHAIN_BSC]: '0x12D703b9a983d0a24612a02304d0df6800e7CF81',
    [CHAIN_BSC_TESTNET]: '0x78E785b4aA545a193a4C95c5476125d8763d0819'
}
export const ORACLE : {[chainID: number]: string} = {
    [CHAIN_BSC]: '0x4ce40EA29e4Bb0d9493BdF02a3E5664F064165A6',
    [CHAIN_BSC_TESTNET]: ''
}
export const USDT : {[chainID: number]: string} = {
    [CHAIN_BSC]: '0x55d398326f99059fF775485246999027B3197955',
    // [CHAIN_BSC]: '0xB0EA4A7682cE261be2391a3C196b162cD72210a0',
    [CHAIN_BSC_TESTNET]: '0xa9db961085f202B3EdF26b1C3F19E5176b95235A',
}
export const BTC : {[chainID: number]: string} = {
    [CHAIN_BSC]: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
    // [CHAIN_BSC]: '0x64341d47DEB1BAa7728215193b7EA90C4BA9852E',
    [CHAIN_BSC_TESTNET]: ''
}
export const ETH : {[chainID: number]: string} = {
    [CHAIN_BSC]: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    // [CHAIN_BSC]: '0xD414D84905933EC8fdfD3A24B9471832688568E4',
    [CHAIN_BSC_TESTNET]: ''
}
export const FIL : {[chainID: number]: string} = {
    [CHAIN_BSC]: '0x0D8Ce2A99Bb6e3B7Db580eD848240e4a0F9aE153',
    // [CHAIN_BSC]: '0xF8F430807013b6ab35203F1892e516F8567Ed64D',
    [CHAIN_BSC_TESTNET]: ''
}

export const XRP : {[chainID: number]: string} = {
    [CHAIN_BSC]: '0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe',
    // [CHAIN_BSC]: '0xf330dBc4C6B192B4edfD4e092F7506785B292fB6',
    [CHAIN_BSC_TESTNET]: ''
}
export const LTC : {[chainID: number]: string} = {
    [CHAIN_BSC]: '0x4338665cbb7b2485a8855a139b75d5e34ab0db94',
    // [CHAIN_BSC]: '0x094a0C55f338C05beb54074c8574B9B44E214dc1',
    [CHAIN_BSC_TESTNET]: ''
}
export const DOGE : {[chainID: number]: string} = {
    [CHAIN_BSC]: '0xba2ae424d960c26247dd6c32edc70b295c744c43',
    // [CHAIN_BSC]: '0x8A2eEE37E6CC3CE8d0D06eb6714C35Cd059a782d',
    [CHAIN_BSC_TESTNET]: ''
}
export const SHIB : {[chainID: number]: string} = {
    [CHAIN_BSC]: '0x2859e4544c4bb03966803b044a93563bd2d0dd4d',
    // [CHAIN_BSC]: '0x0E8F69F4Ffa91532D23E2734077E231020c7b93A',
    [CHAIN_BSC_TESTNET]: ''
}
export const WBNB : {[chainID: number]: string} = {
    [CHAIN_BSC]: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    // [CHAIN_BSC]: '0x83a1fDb3B7021EB903A7e34242f7BfB971527E50',
    [CHAIN_BSC_TESTNET]: ''
}
export const TRX : {[chainID: number]: string} = {
    [CHAIN_BSC]: '0x85eac5ac2f758618dfa09bdbe0cf174e7d574d5b',
    // [CHAIN_BSC]: '0x8471ee5D19E42a98D775543ff662f1835d03E718',
    [CHAIN_BSC_TESTNET]: ''
}

export const BAK: {[chainID: number]: string} = {
    [CHAIN_BSC]: '0xD5401836a2db99705F3B95C341d730c200CC612C',
    [CHAIN_BSC_TESTNET]: '0xCe44031527291E8091abC0041790Ea07f7C9F78a',
}
export const BAKW: {[chainID: number]: string} = {
    [CHAIN_BSC]: '0x34c922901684422854C5a3EBD4004F5d48861aF2',
    // [CHAIN_BSC]: '0xfB7389dbf9aA6b065359f8252c6119F8fe966648',
    [CHAIN_BSC_TESTNET]: '0x96A9347f16b36820b7C2154C89D218544f8D9e51',
}
export const RSV: {[chainID: number]: string} = {
    [CHAIN_BSC]: '0x29980F1ee607eaf7056F528AAB170F3498dafb58',
    [CHAIN_BSC_TESTNET]: '',
}

export const Pairs: {[chainID: number]: string} = {
    [CHAIN_BSC]: '0xd3CB2c3dDC671fc975198039e7EC53e88E76c2a8',
    [CHAIN_BSC_TESTNET]: '',
}

export const BAK_SWAP: {[chainID: number]: string} = {
    [CHAIN_BSC]: '0x92680A6F071BA355EA19F0063723486DC4ccd7Aa',
    [CHAIN_BSC_TESTNET]: '0xaaD55a5Bd13D11F152cC9E3F4dA893495B416846',
}

export const ROUTER_ADDRESS = {
    [CHAIN_BSC]: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
    [CHAIN_BSC_TESTNET]: '0xD99D1c33F9fC3444f8101754aBC46c52416550D1',
}

export const BAKU: {[chainID: number]: string} = {
    [CHAIN_BSC]: '0xE82D3e8904fd2b5412C2C84e4EC7b3C155E58439',
    [CHAIN_BSC_TESTNET]: '0x0d157e68f44436519513dbe3a4EdA2c2599319B3',
}
export const BAKU_POOL: {[chainID: number]: string} = {
    [CHAIN_BSC]: '0xc9786D29c09f3bE5AB900B0003109B50F5cBA467',
    [CHAIN_BSC_TESTNET]: '0xb2C779C2BA939cBF7315d64550cFd85098549cA2'
}

export const BAKU_POOL_V2: {[chainID: number]: string} = {
    [CHAIN_BSC]: '0x8b04B0a2b8faC69FCee5d4F8A60a13a0cf109009',
    [CHAIN_BSC_TESTNET]: '0x2e4D92d6873d3F5B0c2452836635941080F852eA'
}

export const RSVPool: {[chainID: number]: string} = {
    [CHAIN_BSC]: '0xfAfC74f384eFD9d1A63082925E69439f1adeC5A4',
    [CHAIN_BSC_TESTNET]: '',
}