import React from 'react'
import { SvgIcon } from '@mui/material'
import { IconProps } from '../types'

const Add:React.FC<IconProps> = () => {
    return (
        <SvgIcon viewBox="0 0 24 24">
            <path d="M11 11.0005V5.00049H13V11.0005H19V13.0005H13V19.0005H11V13.0005H5V11.0005H11Z" fill="#7C8084"/>
        </SvgIcon>
    )
}

export default Add

