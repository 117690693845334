import React from 'react'
import { SvgIcon } from '@mui/material'
import { IconProps } from '../types'

const Downarrow:React.FC<IconProps> = ({ color = '#7C8084'}) => {
    return (
        <SvgIcon viewBox="0 0 24 24">
            <path fill={color} d="M12.9993 16.1725L18.3633 10.8085L19.7773 12.2225L11.9993 20.0005L4.22134 12.2225L5.63534 10.8085L10.9993 16.1725L10.9993 4.00049L12.9993 4.00049L12.9993 16.1725Z" />
        </SvgIcon>
    )
}

export default Downarrow
