import React from 'react'
import { SvgIcon } from '@mui/material'
import { IconProps } from '../types'

const Time:React.FC<IconProps> = ({ color = '#7C8084'}) => {
    return (
        <SvgIcon viewBox="0 0 24 26">
            <path fill={color}  d="M12 2.81592C17.523 2.81592 22 7.37552 22 13.0004C22 18.6253 17.523 23.1849 12 23.1849C6.477 23.1849 2 18.6253 2 13.0004H4C4 17.4999 7.582 21.148 12 21.148C16.418 21.148 20 17.4999 20 13.0004C20 8.50091 16.418 4.85282 12 4.85282C9.536 4.85282 7.332 5.98737 5.865 7.77068L8 9.94507H2V3.83437L4.447 6.3255C6.28 4.17657 8.984 2.81592 12 2.81592ZM13 7.90817V12.5778L16.243 15.8806L14.828 17.3217L11 13.421V7.90817H13Z"/>
        </SvgIcon>
    )
}

export default Time