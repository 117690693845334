import React from 'react';

// material-ui
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// // project import
// import Palette from './palette';
// import Typography from './typography';
// import CustomShadows from './shadows';
// import componentsOverride from './overrides';

// ==============================|| DEFAULT THEME - MAIN  ||============================== //

const ThemeCustomization:React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ children }) => {
    const themes = createTheme({
        palette:{
            primary: {
                main: '#E05364',
            },
            divider: 'rgba(255, 255, 255, 0.1)',
            background: {
                default: '#21272E'
            }
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 768,
                md: 1024,
                lg: 1266,
                xl: 1536
            }
        },
        direction: 'ltr',
        mixins: {
            toolbar: {
                minHeight: 60,
                paddingTop: 8,
                paddingBottom: 8
            }
        },
        components: {
            MuiCircularProgress: {
                styleOverrides: {
                    root: {
                       width: '20px !important',
                       height: '20px !important',
                       color: '#21272E'
                    }
                }
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        background: '#252B32'
                    }
                }
            },
            MuiDialog: {
                styleOverrides: {
                    paper: {
                        background: '#252B32',
                        borderRadius: '24px'
                    }
                }
            },
            MuiButton: {
                defaultProps: {
                    variant: 'contained'
                },
                styleOverrides: {
                    root: {
                        borderRadius: '12px',
                        color: '#FFFFFF',
                        fontSize: '14px',
                        fontWeight: '700',
                        textTransform: 'none',
                        "&:hover": {
                            opacity: '0.8'
                        }
                    },
                    sizeLarge: {
                        padding: '14px 20px'
                    }
                }
            },
            MuiLinearProgress: {
                styleOverrides: {
                    root: {
                        height: '12px',
                        borderRadius: '10px',
                        background: '#1D232A'
                    },
                    bar: {
                        borderRadius: '10px',
                    }
                }
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        width: '100%',
                        color: 'rgba(255, 255, 255, 0.8)',
                        fontSize: '24px',
                        fontWeight: '700',
                    },
                    
                }
            },
            MuiCssBaseline: {
                styleOverrides: {
                    "input::placeholder": {
                        color: 'color: rgba(255, 255, 255, 0.8)',
                    },
                    '*': {
                    '-ms-overflow-style': 'none',
                    'scrollbar-width:': 'none',
                    },
                    a: {
                        textDecoration: 'none',
                    },
                    '.Mui-disabled': {
                        background: 'rgba(255, 255, 255, 0.1) !important',
                        color: 'rgba(255, 255, 255, 0.2) !important'
                    }
                },
            }
        }
    });
    // themes.components = componentsOverride(themes);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default ThemeCustomization
