import * as React from 'react';
import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import i18n from 'i18next'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { LanguageIcon } from 'components/SvgIcons'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import IconButton from '@mui/material/IconButton';

const LauageText = styled.div`
  display: flex;
  width: 120px;
  justify-content: space-between;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 36px;
  padding: 10px 20px;
  cursor: pointer;
  svg {
    width: 16px;
    height: 16px;
  }
  @media screen and (max-width: 900px) {
    width: 100%;
    padding: 10px 16px;
  }
`

export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (value: string) => {
    return i18n.changeLanguage(value as string)
  }
  return (
    <>
      {/* <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ 
            mr: 2, 
            background: 'rgba(255, 255, 255, 0.2)',
            borderRadius: '12px',
            padding: '10px'
          }}
        >
          <LanguageIcon />
        </IconButton>
      </Box> */}
      <LauageText onClick={handleClick}>
        <Box sx={{display: 'flex'}}>
          <LanguageIcon/>
          <Typography
            color="rgba(255, 255, 255, 0.4)"
            ml="4px"
            fontWeight="700"
            whiteSpace="nowrap"
            fontSize="12px">{i18n.language === 'en-US' ? 'English' : '繁體中文'}
          </Typography>
        </Box>
        <ExpandMoreIcon style={{color: 'rgba(255, 255, 255, 0.4)'}} />
      </LauageText>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            background: '#12171E',
            padding: '5px',
            borderRadius: '12px',
            color: 'rgba(255, 255, 255, 0.8)',
            mt: 1.5,
            '.MuiList-root': {
              padding: '5px',
              
            },
            '& .MuiMenuItem-root': {
              padding: '10px',
              width: '130px',
              '&:hover': {
                background: '#1D232A',
                borderRadius: '6px'
              }
            },
            // '&:before': {
            //   content: '""',
            //   display: 'block',
            //   position: 'absolute',
            //   top: 0,
            //   right: 14,
            //   width: 10,
            //   height: 10,
            //   bgcolor: '#12171E',
            //   transform: 'translateY(-50%) rotate(45deg)',
            //   zIndex: 0,
            // },
          },
        }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      >
        <MenuItem onClick={() => handleChange('en-US')}>
          English
        </MenuItem>
        <MenuItem onClick={() => handleChange('zh-CN')}>
          繁體中文
        </MenuItem>
      </Menu>
    </>
  )
}
