import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// // material-ui
// import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material'
import { drawerWidth } from 'config'

import Header from './Header'
import Drawer from './Drawer'
import Background, { FooterBackground } from './Background';
// import Footer from './Footer'

const MainLayout = () => {
    const [open, setOpen] = useState(false)
    const handelMenu = (v: boolean) => {
        console.log("v", v)
        setOpen(v)
    }
    return (
        <>
            <Header onMenu={handelMenu} />
            <Drawer open={open} onMenu={handelMenu} />
            <Background />
            <FooterBackground />
            <Box component="main" 
                sx={{
                    position: 'relative',
                    marginLeft: {xs: '0', md: `${drawerWidth}px`},
                    p: {xs: '80px 15px 0', md: '80px 0 0'}}}
                >
                <Box maxWidth="1200px" m="0 auto"><Outlet /></Box>
            </Box>
        </>
    )
}

export default MainLayout