import { useEffect } from 'react' 
import { setupNetwork } from 'utils/network'
import { CHAIN_BSC } from '@w3u/chains'

const useCheckSupportChain = () => {
    const supportChainId = Number(process.env.REACT_APP_CHAIN_ID) ?? CHAIN_BSC
    const changeChain = async () => {
        if (supportChainId) {
            await setupNetwork(supportChainId)
        }
    }
    useEffect(() => {
        changeChain()
    }, [changeChain, supportChainId])
}

export default useCheckSupportChain 