import { Box, Typography, Skeleton } from '@mui/material'
import Logo from 'assets/images/logo.svg'
import useTokenPrice from "hooks/useTokenPrice"
import { getBakAddress } from 'utils/addressHelpers'
import { displayBalance } from 'utils/formatBalance'

const BakPrice = () => {
    const { loading, getPriceByAddress } = useTokenPrice()
    const bakAddress = getBakAddress()
    const bakPrice = getPriceByAddress(bakAddress)
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '20px'
            }}
        >
            <img width="36px" height="36px" src={Logo} alt="Logo" />
            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: '12px'}}>
                <Typography fontSize="12px" color="rgba(255, 255, 255, 0.7)">BAK</Typography>
                {loading ? 
                    <Typography color="#E1C06A" fontWeight="500">${displayBalance(bakPrice, 0)}</Typography> :
                    <Skeleton width='60px' height='24px' />}
            </Box>
        </Box>
    )
}

export default BakPrice