import React from 'react'
import { SvgIcon } from '@mui/material'
import { IconProps } from '../types'

const Init:React.FC<IconProps> = ({ color = '#ffffff'}) => {
    return (
        <SvgIcon viewBox="0 0 16 16">
            <path fill={color} fill-rule="evenodd" clip-rule="evenodd" d="M3.30492 3.46157C3.03052 4.35097 3.03052 5.31566 3.30492 6.20505C6.4278 6.50603 9.57239 6.50603 12.6953 6.20505C12.9697 5.31566 12.9697 4.35097 12.6953 3.46157C9.57239 3.1606 6.4278 3.1606 3.30492 3.46157ZM3.13926 2.47295C6.37211 2.1568 9.62809 2.1568 12.8609 2.47295C13.2173 2.50781 13.5168 2.752 13.6263 3.08918C13.9927 4.21791 13.9927 5.44872 13.6263 6.57744C13.5168 6.91463 13.2173 7.15882 12.8609 7.19367C9.62809 7.50982 6.37211 7.50982 3.13926 7.19367C2.7829 7.15882 2.48338 6.91463 2.37393 6.57744C2.00752 5.44872 2.00752 4.21791 2.37393 3.08918C2.48338 2.752 2.7829 2.50781 3.13926 2.47295Z" />
            <path fill={color} fill-rule="evenodd" clip-rule="evenodd" d="M3.30492 9.79491C3.03052 10.6843 3.03052 11.649 3.30492 12.5384C6.4278 12.8394 9.57239 12.8394 12.6953 12.5384C12.9697 11.649 12.9697 10.6843 12.6953 9.79491C9.57239 9.49393 6.4278 9.49393 3.30492 9.79491ZM3.13926 8.80629C6.37211 8.49014 9.62809 8.49014 12.8609 8.80629C13.2173 8.84114 13.5168 9.08533 13.6263 9.42251C13.9927 10.5512 13.9927 11.782 13.6263 12.9108C13.5168 13.248 13.2173 13.4922 12.8609 13.527C9.62809 13.8432 6.37211 13.8432 3.13926 13.527C2.7829 13.4922 2.48338 13.248 2.37393 12.9108C2.00752 11.7821 2.00752 10.5512 2.37393 9.42251C2.48338 9.08533 2.7829 8.84114 3.13926 8.80629Z" />
        </SvgIcon>
    )
}

export default Init

