import React, { createContext, useState } from "react";
import { PriceData } from 'Modal'
import usePrices from 'hooks/usePrices'

interface PricesContextApi {
    prices: PriceData []
    loading: boolean
    getPriceByAddress: (address: string) => string
    getPriceBySymbol: (symbol: string) => string
}

export const PricesContext = createContext<PricesContextApi>({
    prices: [],
    loading: false,
    getPriceByAddress: () => '',
    getPriceBySymbol: () => ''
})

export const PricesProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
    const { prices, loading } = usePrices()
    // const prices: any [] = []
    // const loading = false
    const getPriceByAddress = (address: string) => {
        const token = prices.find(f => f.address.toLocaleLowerCase() === address.toLocaleLowerCase())
        return token?.price ?? ''
    }
    const getPriceBySymbol = (symbol: string) => {
        const token = prices.find(f => f.symbol.toLocaleLowerCase() === symbol.toLocaleLowerCase())
        return token?.price ?? ''
    }
    return (
        <PricesContext.Provider value={{
            prices,
            loading,
            getPriceByAddress,
            getPriceBySymbol
        }}>
            {children}
        </PricesContext.Provider>
    )
}