import React from 'react'
import { SvgIcon } from '@mui/material'
import { IconProps } from '../types'

const Holder:React.FC<IconProps> = ({ color = '#ffffff'}) => {
    return (
        <SvgIcon viewBox="0 0 24 24">
            <path fill={color} d="M12 2C14.2543 2 16.4163 2.89553 18.0104 4.48959C19.6045 6.08365 20.5 8.24566 20.5 10.5C20.5 17 15 22.5 12 22.5C9 22.5 3.5 17 3.5 10.5C3.5 8.24566 4.39553 6.08365 5.98959 4.48959C7.58365 2.89553 9.74566 2 12 2ZM17.5 12C16.869 12 16.245 12.1327 15.6686 12.3895C15.0922 12.6463 14.5762 13.0214 14.1542 13.4906C13.7322 13.9597 13.4136 14.5124 13.219 15.1127C13.0245 15.713 12.9584 16.3475 13.025 16.975C13.6924 17.0459 14.3672 16.9665 15 16.7428C15.6328 16.5191 16.2075 16.1567 16.6821 15.6821C17.1567 15.2075 17.5191 14.6328 17.7428 14C17.9665 13.3672 18.0459 12.6924 17.975 12.025C17.8172 12.0084 17.6587 12 17.5 12ZM6.5 12C6.34 12 6.181 12.008 6.025 12.025C5.95413 12.6924 6.03345 13.3672 6.25716 14C6.48086 14.6328 6.84328 15.2075 7.31786 15.6821C7.79245 16.1567 8.36719 16.5191 8.99998 16.7428C9.63277 16.9665 10.3076 17.0459 10.975 16.975C11.0416 16.3475 10.9755 15.713 10.781 15.1127C10.5864 14.5124 10.2678 13.9597 9.8458 13.4906C9.42379 13.0214 8.90783 12.6463 8.33142 12.3895C7.75501 12.1327 7.13103 12 6.5 12Z" fill-opacity="0.6"/>
        </SvgIcon>
    )
}

export default Holder