import React, { createContext, useState } from 'react'
import { Snackbar } from '@mui/material'

export interface ToastContextApi {
    toast: (message: string) => void
}

export const ToastsContext = createContext<ToastContextApi>({toast: () => {}})


export const ToastsProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState('')

    const handleToasts = (msg: string) => {
        setMessage(msg)
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    return (
        <ToastsContext.Provider value={{toast: handleToasts}}>
            {children}
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={open}
                onClose={handleClose}
                message={message}
            />
        </ToastsContext.Provider>
    )
}
