import useEagerConnect from 'hooks/useEagerConnect'

export default function Web3ReactManager({children}: {children: JSX.Element}) {

  const triedEager = useEagerConnect()

// useNetworkListener()
  if (!triedEager) {
    return null
  }

  return children
}
