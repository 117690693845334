import { Box, Drawer, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import LanguageSelect from 'components/Language'
import DrawerHeader from './DrawerHeader'
import DrawerContent from './DrawerContent'
import BakPrice from './BakPrice'
const drawerWidth = 180

interface Props {
    open: boolean
    onMenu: (open: boolean) => void
}

const MainDrawer:React.FC<Props> = ({ open, onMenu }) => {
    // const [drawerOpen, setDrawerOpen] = useState(open)
    const theme = useTheme();
    const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));
    const container = window !== undefined ? () => window.document.body : undefined;
    // useEffect(() => {setDrawerOpen(open)}, [open])
    const handleDrawerToggle = () => {
        onMenu(false)
    }
    return (
        <Box component="nav" sx={{
            position: 'fixed',
            left: 0,
            top: 0,
            height: '100vh',
            zIndex: 1300,
        }}>
            {!matchDownMD ? 
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: drawerWidth,
                        padding: '30px',
                        margin: '20px',
                        height: 'calc(100% - 100px)',
                        boxSizing: 'content-box',
                        borderRadius: '24px',
                        background: '#252B32',
                        backdropFilter: 'blur(20px)',
                    }}
                >
                    <Box sx={{display: 'flex', flexDirection: 'column', flex: 'auto'}}>
                        <DrawerHeader />
                        <DrawerContent />
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'column',}}>
                        <BakPrice />
                        <LanguageSelect />
                    </Box>
                </Box> :
                <Drawer
                    container={container}
                    variant="temporary"
                    open={open}
                    onClose={handleDrawerToggle}
                    ModalProps={{ keepMounted: true }}
                    sx={{
                        display: { xs: 'flex', lg: 'none' },
                        flexDirection: 'column',
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: '43%',
                            // borderRight: `1px solid ${theme.palette.divider}`,
                            backgroundImage: 'none',
                            boxShadow: 'inherit',
                            padding: '20px',
                        }
                    }}
                >
                    <Box sx={{display: 'flex', flexDirection: 'column', flex: 'auto'}}>
                        <DrawerHeader />
                        <DrawerContent onClose={handleDrawerToggle} />
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'column',}}>
                        <BakPrice />
                        <LanguageSelect />
                    </Box>
                </Drawer>
            }
        </Box>
    )
}

export default MainDrawer