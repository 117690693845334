import React from 'react'
import { SvgIcon } from '@mui/material'
import { IconProps } from '../types'

const Fire:React.FC<IconProps> = ({ color = '#DB7163'}) => {
    return (
        <SvgIcon viewBox="0 0 16 16">
            <path fill={color} d="M8.0417 1.37668C7.84861 1.29202 7.65843 1.35125 7.53609 1.47584C7.41726 1.59687 7.35782 1.78158 7.39949 1.96782C7.46526 2.26178 7.5 2.56761 7.5 2.88176C7.5 3.59899 7.31923 4.10133 7.0082 4.51776C6.69202 4.94108 6.23432 5.28572 5.65644 5.67457C5.64736 5.68069 5.6389 5.68768 5.63118 5.69545L5.56119 5.76598C4.3213 6.56702 3.5 7.96175 3.5 9.54843C3.5 12.0337 5.51472 14.0484 8 14.0484C10.4853 14.0484 12.5 12.0337 12.5 9.54843C12.5 8.81872 12.3262 8.12913 12.0176 7.51925C11.9925 7.46964 11.9443 7.43581 11.8891 7.42907C11.8339 7.42232 11.779 7.44355 11.7426 7.48566C11.6956 7.54016 11.6496 7.59395 11.6042 7.64695C11.0187 8.33107 10.5462 8.88316 9.57744 9.12978C9.53624 9.14026 9.51065 9.13247 9.49249 9.12112C9.47112 9.10777 9.44773 9.08112 9.43171 9.03853C9.39832 8.94978 9.41265 8.8366 9.48107 8.76241C9.99919 8.20068 10.3673 7.43815 10.4988 6.35341C10.7452 4.32147 9.82166 2.15708 8.0417 1.37668Z" />
        </SvgIcon>
    )
}

export default Fire
