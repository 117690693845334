import React from 'react'
import { SvgIcon } from '@mui/material'
import { IconProps } from '../types'

const Close:React.FC<IconProps> = () => {
    return (
        <SvgIcon viewBox="0 0 24 24">
            <g clip-path="url(#clip0_513_1734)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.0714 19.0716C18.5832 19.5597 17.7918 19.5597 17.3036 19.0716L4.92925 6.69719C4.44109 6.20904 4.44109 5.41758 4.92925 4.92942C5.4174 4.44127 6.20886 4.44127 6.69702 4.92942L19.0714 17.3038C19.5595 17.7919 19.5595 18.5834 19.0714 19.0716Z" fill="#7C8084"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.0712 4.92941C19.5593 5.41757 19.5594 6.20902 19.0712 6.69718L6.69683 19.0715C6.20867 19.5597 5.41721 19.5597 4.92906 19.0715C4.4409 18.5834 4.4409 17.7919 4.92906 17.3038L17.3034 4.92941C17.7916 4.44125 18.583 4.44125 19.0712 4.92941Z" fill="#7C8084"/>
            </g>
            <defs>
            <clipPath id="clip0_513_1734">
                <rect width="24" height="24" fill="white" transform="translate(0 0.000488281)"/>
            </clipPath>
            </defs>
        </SvgIcon>
    )
}

export default Close

