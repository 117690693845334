import { useState, useEffect } from 'react'
import { useWeb3React } from "@web3-react/core"
import { injectedConnector } from 'config/connectors'
import {isMobile} from 'react-device-detect'

const useEagerConnect = (): boolean => {
    const {activate, active} = useWeb3React()
    const [tried, setTried] = useState(false)
  
    useEffect(() => {
      injectedConnector.isAuthorized().then((isAuthorized: boolean) => {
        if (isAuthorized) {
          activate(injectedConnector, undefined, true).catch(() => {
            setTried(true)
          })
        } else {
          if (isMobile && window.ethereum) {
            activate(injectedConnector, undefined, true).catch(() => {
              setTried(true)
            })
          } else {
            setTried(true)
          }
        }
      })
    }, [activate])
  
    useEffect(() => {
      if (active) {
        setTried(true)
      }
    }, [active])
  
    return tried
  }

  export default useEagerConnect 