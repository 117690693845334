import React from 'react'
import { SvgIcon } from '@mui/material'
import { IconProps } from '../types'

const Leftarrow:React.FC<IconProps> = () => {
    return (
        <SvgIcon viewBox="0 0 24 25">
            <path d="M10.8273 12.1282L15.7773 17.1308L14.3633 18.5599L7.99934 12.1282L14.3633 5.69647L15.7773 7.12551L10.8273 12.1282Z" fill="white"/>
        </SvgIcon>
    )
}

export default Leftarrow

