import styled from '@emotion/styled'
import { useLocation } from 'react-router-dom'
import { useMediaQuery, useTheme } from '@mui/material'
import HomeImg from 'assets/images/background/home.png'
import FootrImg from 'assets/images/background/footer.png'
import FootrPoolv2Img from 'assets/images/background/pool_v2.png'
import HomeImgMobile from 'assets/images/background/home_mobile.png'
import StakeImg from 'assets/images/background/stake.png'
import StakeImgMobile from 'assets/images/background/stake_mobile.png'
import SwapImg from 'assets/images/background/swap.png'
import SwapImgMobile from 'assets/images/background/swap_mobile.png'
import NodeImg from 'assets/images/background/node.png'
import NodeImgMobile from 'assets/images/background/node_mobile.png'
import HolderImg from 'assets/images/background/holder.png'
import HolderImgMobile from 'assets/images/background/holder_mobile.png'

import { useMemo } from 'react'

const Wrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
`

const FooterWrapper = styled.div`
    position: fixed;
    right: 0;
    bottom: 0;
`

export const FooterBackground = () => {
    const loaction = useLocation()
    const theme = useTheme();
    const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <>
            {loaction.pathname === '/' || loaction.pathname === '/poolv2' || loaction.pathname === '/pool' ?
                <FooterWrapper>
                    {!matchDownMD ? <img width="700px" src={FootrImg} alt="home" /> : <img width="100%" src={loaction.pathname === '/poolv2' ? FootrPoolv2Img : FootrImg} alt="home" />}
                </FooterWrapper> : null
        }
        </>
    )
}

const Background = () => {
    const loaction = useLocation()
    const theme = useTheme();
    const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));
    const imgUrl = useMemo(() => {
        if (loaction.pathname === '/' || loaction.pathname === '/poolv2' || loaction.pathname === '/pool') {
            return !matchDownMD ? HomeImg : HomeImgMobile
        } else if (loaction.pathname === '/stake') {
            return !matchDownMD ? StakeImg : StakeImgMobile
        } else if (loaction.pathname === '/swap' || loaction.pathname === '/liquidity') {
            return !matchDownMD ? SwapImg : SwapImgMobile
        } else if (loaction.pathname === '/nodes' || loaction.pathname === '/invite') {
            return !matchDownMD ? NodeImg : NodeImgMobile
        }  else if (loaction.pathname === '/holder') {
            return !matchDownMD ? HolderImg : HolderImgMobile
        }
        return !matchDownMD ? HomeImg : HomeImgMobile
    }, [loaction, matchDownMD])

    return (
        <Wrapper>
            <img width="100%" src={imgUrl} alt="home" />
            {/* {!matchDownMD ?  : <img width="100%" src={HomeImgMobile} alt="home" /> } */}
        </Wrapper>
    )
}

export default Background