import React from 'react'
import { SvgIcon } from '@mui/material'
import { IconProps } from '../types'

const Help:React.FC<IconProps> = () => {
    return (
        <SvgIcon viewBox="0 0 14 15">
            <g clip-path="url(#clip0_457_1352)">
                <path d="M7 13.3333C10.2218 13.3333 12.8333 10.7218 12.8333 7.5C12.8333 4.27825 10.2218 1.66667 7 1.66667C3.77825 1.66667 1.16667 4.27825 1.16667 7.5C1.16667 10.7218 3.77825 13.3333 7 13.3333ZM7 14.5C3.13425 14.5 0 11.3658 0 7.5C0 3.63425 3.13425 0.5 7 0.5C10.8658 0.5 14 3.63425 14 7.5C14 11.3658 10.8658 14.5 7 14.5Z" fill="white" fill-opacity="0.4"/>
                <path d="M7.12242 3.67334C6.27076 3.67334 5.59409 3.91834 5.10409 4.42001C4.60242 4.91001 4.35742 5.57501 4.35742 6.42667H5.59409C5.59409 5.91334 5.69909 5.50501 5.90909 5.22501C6.14242 4.89834 6.52742 4.73501 7.05242 4.73501C7.47242 4.73501 7.79909 4.85167 8.03242 5.08501C8.25409 5.31834 8.37076 5.63334 8.37076 6.04167C8.37076 6.34501 8.26576 6.62501 8.05576 6.89334L7.85742 7.11501C7.13409 7.75667 6.69076 8.23501 6.52742 8.56167C6.37576 8.86501 6.30576 9.23834 6.30576 9.67001V9.86834H7.55409V9.67001C7.55409 9.37834 7.61242 9.12167 7.74075 8.88834C7.84575 8.67834 8.00909 8.48001 8.21909 8.29334C8.77909 7.81501 9.10576 7.51167 9.21076 7.38334C9.49076 7.01001 9.64242 6.53167 9.64242 5.96001C9.64242 5.26001 9.40909 4.70001 8.95409 4.29167C8.48742 3.87167 7.88076 3.67334 7.12242 3.67334ZM6.92409 10.4983C6.67909 10.4983 6.48076 10.5683 6.32909 10.7317C6.15409 10.8833 6.07242 11.0817 6.07242 11.3267C6.07242 11.56 6.15409 11.7583 6.32909 11.9217C6.48076 12.085 6.67909 12.1667 6.92409 12.1667C7.15742 12.1667 7.36742 12.085 7.54242 11.9333C7.70575 11.77 7.78742 11.5717 7.78742 11.3267C7.78742 11.0817 7.70575 10.8833 7.54242 10.7317C7.37909 10.5683 7.16909 10.4983 6.92409 10.4983Z" fill="white" fill-opacity="0.4"/>
            </g>
            <defs>
            <clipPath id="clip0_457_1352">
                <rect width="14" height="14" fill="white" transform="translate(0 0.5)"/>
            </clipPath>
            </defs>
        </SvgIcon>
    )
}

export default Help

