import { Address } from 'Modal'
import { MULTICALL, ALPHA_BTC_POOL, ALPHA_ETH_POOL, ALPHA_FIL_POOL,
    ALPHA_DOGE_POOL, ALPHA_LTC_POOL, ALPHA_SHIB_POOL, ALPHA_TRX_POOL,
    ALPHA_BNB_POOL,ALPHA_XRP_POOL, BATE_POOL, INVITE_ROOT, REWARD_CLAIM, 
    ORACLE, USDT, BTC, ETH, FIL, BAK, BAKW, RSV, Pairs, BAK_SWAP, ROUTER_ADDRESS,
    XRP, LTC, DOGE, SHIB, WBNB, TRX, BAKU, BAKU_POOL, INVITE_ROOT_BAKU, REWARD_CLAIM_2,
    BAKU_POOL_V2, RSVPool,
    ALPHA_BTC_POOL_V2,
    ALPHA_ETH_POOL_V2,
    ALPHA_FIL_POOL_V2,
    ALPHA_XRP_POOL_V2,
    ALPHA_LTC_POOL_V2,
    ALPHA_SHIB_POOL_V2,
    ALPHA_TRX_POOL_V2,
    ALPHA_BNB_POOL_V2,
    ALPHA_DOGE_POOL_V2
} from 'config/contents'
import { default_chain } from 'config'
import { chain } from 'lodash'

export const getAddress = (address: Address, chainId?: number): string => {
    const _chainId = chainId ?? default_chain
    return address[_chainId]
}

export const getMulticallAddress = (chainId?: number) => {
    return getAddress(MULTICALL, chainId)
}
export const getAlphaPoolBTCAddress = (chainId?: number) => {
    return getAddress(ALPHA_BTC_POOL, chainId)
}
export const getAlphaPoolETHAddress = (chainId?: number) => {
    return getAddress(ALPHA_ETH_POOL, chainId)
}
export const getAlphaPoolFILAddress = (chainId?: number) => {
    return getAddress(ALPHA_FIL_POOL, chainId)
}
export const getAlphaPoolXRPAddress = (chainId?: number) => {
    return getAddress(ALPHA_XRP_POOL, chainId)
}
export const getAlphaPoolDOGEAddress = (chainId?: number) => {
    return getAddress(ALPHA_DOGE_POOL, chainId)
}
export const getAlphaPoolLTCAddress = (chainId?: number) => {
    return getAddress(ALPHA_LTC_POOL, chainId)
}
export const getAlphaPoolSHIBAddress = (chainId?: number) => {
    return getAddress(ALPHA_SHIB_POOL, chainId)
}
export const getAlphaPoolTRXAddress = (chainId?: number) => {
    return getAddress(ALPHA_TRX_POOL, chainId)
}
export const getAlphaPoolBNBAddress = (chainId?: number) => {
    return getAddress(ALPHA_BNB_POOL, chainId)
}
export const getAlphaPoolV2BTCAddress = (chainId?: number) => {
    return getAddress(ALPHA_BTC_POOL_V2, chainId)
}
export const getAlphaPoolV2ETHAddress = (chainId?: number) => {
    return getAddress(ALPHA_ETH_POOL_V2, chainId)
}
export const getAlphaPoolV2FILAddress = (chainId?: number) => {
    return getAddress(ALPHA_FIL_POOL_V2, chainId)
}
export const getAlphaPoolV2XRPAddress = (chainId?: number) => {
    return getAddress(ALPHA_XRP_POOL_V2, chainId)
}
export const getAlphaPoolV2DOGEAddress = (chainId?: number) => {
    return getAddress(ALPHA_DOGE_POOL_V2, chainId)
}
export const getAlphaPoolV2LTCAddress = (chainId?: number) => {
    return getAddress(ALPHA_LTC_POOL_V2, chainId)
}
export const getAlphaPoolV2SHIBAddress = (chainId?: number) => {
    return getAddress(ALPHA_SHIB_POOL_V2, chainId)
}
export const getAlphaPoolV2TRXAddress = (chainId?: number) => {
    return getAddress(ALPHA_TRX_POOL_V2, chainId)
}
export const getAlphaPoolV2BNBAddress = (chainId?: number) => {
    return getAddress(ALPHA_BNB_POOL_V2, chainId)
}
export const getBatePoolAddress = (chainId?: number) => {
    return getAddress(BATE_POOL, chainId)
}
export const getInviteRootAddress = (chainId?: number) => {
    return getAddress(INVITE_ROOT, chainId)
}
export const getInviteRootBakuAddress = (chainId?: number) => {
    return getAddress(INVITE_ROOT_BAKU, chainId)
}
export const getRewardClaimAddress = (chainId?: number) => {
    return getAddress(REWARD_CLAIM, chainId)
}
export const getRewardClaimAddress2 = (chainId?: number) => {
    return getAddress(REWARD_CLAIM_2, chainId)
}
export const getOracleAddress = (chainId?: number) => {
    return getAddress(ORACLE, chainId)
}
export const getBakAddress = (chainId?: number) => {
    return getAddress(BAK, chainId)
}
export const getBakWAddress = (chainId?: number) => {
    return getAddress(BAKW, chainId)
}
export const getRSVAddress = (chainId?: number) => {
    return getAddress(RSV, chainId)
}
export const getUSDTAddress = (chainId?: number) => {
    return getAddress(USDT, chainId)
}
export const getBTCAddress = (chainId?: number) => {
    return getAddress(BTC, chainId)
}
export const getETHAddress = (chainId?: number) => {
    return getAddress(ETH, chainId)
}
export const getFILAddress = (chainId?: number) => {
    return getAddress(FIL, chainId)
}
export const getXRPAddress = (chainId?: number) => {
    return getAddress(XRP, chainId)
}
export const getLTCAddress = (chainId?: number) => {
    return getAddress(LTC, chainId)
}
export const getDOGEAddress = (chainId?: number) => {
    return getAddress(DOGE, chainId)
}
export const getSHIBAddress = (chainId?: number) => {
    return getAddress(SHIB, chainId)
}
export const getWBNBAddress = (chainId?: number) => {
    return getAddress(WBNB, chainId)
}
export const getTRXAddress = (chainId?: number) => {
    return getAddress(TRX, chainId)
}
export const getPairAddress = (chainId?: number) => {
    return getAddress(Pairs, chainId)
}
export const getBakSwapAddress = (chainId?: number) => {
    return getAddress(BAK_SWAP, chainId)
}
export const getRouterAddress = (chainId?: number) => {
    return getAddress(ROUTER_ADDRESS, chainId)
}
export const getBAKUAddress = (chainId?: number) => {
    return getAddress(BAKU, chainId)
}
export const getBAKUPoolAddress = (chainId?: number) => {
    return getAddress(BAKU_POOL, chainId)
}
export const getBAKUPoolV2Address = (chainId?: number) => {
    return getAddress(BAKU_POOL_V2, chainId)
}

export const getRSVPoolAddress = (chainId?: number) => {
    return getAddress(RSVPool, chainId)
}