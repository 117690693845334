import React from 'react'
import { SvgIcon } from '@mui/material'
import { IconProps } from '../types'

const Exchange:React.FC<IconProps> = ({ color = '#EBC277'}) => {
    return (
        <SvgIcon viewBox="0 0 14 9">
            <path fill={color} d="M11 5.00049V3.50049H5V1.50049H11V0.000488281L14 2.50049L11 5.00049Z" />
            <path fill={color} d="M3 9.00049V7.50049H9V5.50049H3V4.00049L0 6.50049L3 9.00049Z" />
        </SvgIcon>
    )
}

export default Exchange
