import React from 'react'
import { SvgIcon } from '@mui/material'
import { IconProps } from '../types'

const Menu:React.FC<IconProps> = ({ color = '#ffffff'}) => {
    return (
        <SvgIcon viewBox="0 0 34 34">
            <path d="M4.25 9.91675H29.75" stroke={color} stroke-width="1.5" strokeLinecap="round"/>
            <path d="M4.25 17H29.75" stroke={color} stroke-width="1.5" strokeLinecap="round"/>
            <path d="M4.25 24.0833H29.75" stroke={color} stroke-width="1.5" strokeLinecap="round"/>
        </SvgIcon>
    )
}

export default Menu
