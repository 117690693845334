import React from 'react'
import { SvgIcon } from '@mui/material'
import { IconProps } from '../types'

const Copy:React.FC<IconProps> = () => {
    return (
        <SvgIcon viewBox="0 0 16 16">
            <path d="M0.390137 4.80054C0.390137 2.36191 2.36704 0.38501 4.80567 0.38501H10.191C10.509 0.38501 10.7669 0.642866 10.7669 0.960948C10.7669 1.27903 10.509 1.53689 10.191 1.53689H4.80567C3.0032 1.53689 1.54201 2.99807 1.54201 4.80054V10.258C1.54201 10.5761 1.28416 10.834 0.966075 10.834C0.647993 10.834 0.390137 10.5761 0.390137 10.258V4.80054Z" fill="#EBC277"/>
            <path d="M12.0261 3.10594C9.53727 2.82778 6.98535 2.82778 4.49656 3.10594C3.78756 3.18518 3.21782 3.74315 3.13425 4.45764C2.83907 6.98139 2.83907 9.53094 3.13425 12.0547C3.21782 12.7692 3.78756 13.3271 4.49656 13.4064C6.98535 13.6845 9.53727 13.6845 12.0261 13.4064C12.7351 13.3271 13.3048 12.7692 13.3884 12.0547C13.6835 9.53094 13.6835 6.98139 13.3884 4.45764C13.3048 3.74315 12.7351 3.18518 12.0261 3.10594Z" fill="#EBC277"/>
        </SvgIcon>
    )
}

export default Copy

