import { BscConnector } from '@binance-chain/bsc-connector'
import { CHAIN_BSC, CHAIN_BSC_TESTNET } from '@w3u/chains'
import {InjectedConnector} from '@web3-react/injected-connector'
import {WalletConnectConnector} from '@web3-react/walletconnect-connector'
import {WalletLinkConnector} from '@web3-react/walletlink-connector'

const INFURA_KEY = process.env.REACT_APP_INFURA_KEY

export const injectedConnector = new InjectedConnector({
  supportedChainIds: [
    CHAIN_BSC,
    CHAIN_BSC_TESTNET
  ],
})

export const bscConnector = new BscConnector({supportedChainIds: [56, 97]})

// MainNet only
export const walletConnect = new WalletConnectConnector({
  rpc: {1: `https://mainnet.infura.io/v3/${INFURA_KEY}`},
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
})

// MainNet only
export const walletLink = new WalletLinkConnector({
  url: `https://mainnet.infura.io/v3/${INFURA_KEY}`,
  appName: 'AzerDAO',
  appLogoUrl: 'https://.png',
})

export const DefaultAccount = '0xffffffffffffffffffffffffffffffffffffffff'

export const ADDRESS_ZERO = '0x0000000000000000000000000000000000000000';
